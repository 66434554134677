import React, { Component } from "react"
import Profile from '../../components/Profile'
import MainContext from '../Webshop/MainContext'
import ChangePassword from "../../components/ChangePassword"
import Login from '../Order/UserData/Login'

class UserProfile extends Component {
    componentDidMount() {
        this.context.setSearchVisible(false)
    }
    render() {
        if(this.context.loggedIn && this.context.dictionary !== null && this.context.selectedLanguage !== null) {
            return (
                <div className="userProfile hidden appear">
                    <h2>Adataim</h2>
                    <ChangePassword 
                        setUserPass={this.context.setUserPass}
                    />
                    <Profile 
                        dictionary={this.context.dictionary}
                        selectedLanguage={this.context.selectedLanguage}
                        endpoint={this.context.endpoint}
                        loginWithToken={this.context.loginWithToken}
                    />                    
                </div>
            );
        } else return (
            <Login 
                userLoggedIn={this.context.loggedIn} 
                validateUserPin={this.context.validateUserPin}
                generateUserPin={this.context.generateUserPin}
                userLoginWithCredentials={this.context.userLoginWithCredentials}
                shopAsGuest={this.context.guestShopping}
            />
        )
    }
}
UserProfile.contextType = MainContext;
export default UserProfile;
