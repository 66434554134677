import React, {Component} from 'react'
import MainContext from '../../../screens/Webshop/MainContext'
import Breadcrumbs from './Breadcrumbs'
import LoadAssets from '../../LoadAssets'
import CountButton from '../../CountButton'

import {ReactComponent as CircleTrashIconActive} from "../../../svg/base/circleTrashIconActive.svg"
import {ReactComponent as CircleMinusIconActive} from "../../../svg/base/circleMinusIconActive.svg"
import {ReactComponent as CirclePlusIconActive} from "../../../svg/base/circlePlusIconActive.svg"
import {ReactComponent as CirclePlusIconPassive} from "../../../svg/base/circlePlusIconPassive.svg"
import {ReactComponent as PlusMinusFieldBg} from "../../../svg/base/plusMinusFieldBg.svg"
import {ReactComponent as CircleMinusIconPassive} from "../../../svg/base/circleMinusIconPassive.svg"

class SelectedItem extends Component {
    onChangeCount = (e) => {
        this.context.setOrderItemCount(this.props.categoryId, this.props.selectedItemId, e.count)
    }
    onAddItem = () => {
        this.context.setOrderItemCount(this.props.categoryId, this.props.selectedItemId, 1)
    }
    render() {
        let item = this.context.orderItemTree[this.props.categoryId].items[this.props.selectedItemId]
        if(item !== undefined) {
            let itemInfo = JSON.parse(item.additional_info)
            let itemImageUrl = LoadAssets(JSON.parse(item.attributes).appImage, this.context.assets)
            if(itemImageUrl === this.context.endpoint+"/undefined") {itemImageUrl = this.context.endpoint+"/assets/img/brand/default_item.png"}
            let currency = this.context.currencies.huf[this.context.selectedLanguage]
            return (
                <div className="selectedItemContainer">
                    <Breadcrumbs 
                        categoryName={JSON.parse(this.context.orderItemTree[this.props.categoryId].name)[this.context.selectedLanguage]}
                        categoryId={this.props.categoryId}
                        itemName={JSON.parse(item.name)[this.context.selectedLanguage]}
                    />
                    <div className="itemImg hidden quickAppear">
                        <img src={itemImageUrl} alt="itemImg" />
                    </div>
                    <CountButton 
                        minValue='0'
                        maxValue='2'
                        count={this.context.orderItemTree[this.props.categoryId].items[this.props.selectedItemId].count}
                        onChange={this.onChangeCount}
                        onAddItem={this.onAddItem} 
                        containerBgImage={<PlusMinusFieldBg />}
                        plusImage={<CirclePlusIconPassive />} 
                        plusActiveImage={<CirclePlusIconActive />} 
                        minusImage={<CircleMinusIconPassive />} 
                        minusActiveImage={<CircleMinusIconActive />} 
                        oneLeftImage={<CircleMinusIconPassive />}
                        oneLeftActiveImage={<CircleTrashIconActive />}
                    />
                    <div className="itemPrice hidden appear">{item.price>0 && item.price+" "+currency+" / "+JSON.parse(this.context.getItemSizeName(this.context.itemSizes, item.default_size))[this.context.selectedLanguage]}</div>
                    <div className="itemDescription hidden appear">
                        <div className="ingredients">
                            <div className="title">Összetevők</div>
                            <div className="content">
                                {itemInfo.osszetevok != null ? itemInfo.osszetevok : "nincs megadva"}
                            </div>
                        </div>
                        <div className="allergens">
                            <div className="title">Allergének</div>
                            <div className="content">
                                {itemInfo.allergen != null ? itemInfo.allergen : "nincs megadva"}
                            </div>
                        </div>
                        <div className="storage">
                            <div className="title">Tárolás</div>
                            <div className="content">
                                {itemInfo.tarolas != null ? itemInfo.tarolas : "nincs megadva"}
                            </div>
                        </div>
                        <div className="countryOfOrigin">
                            <div className="title">Származási ország</div>
                            <div className="content">
                                {itemInfo.szarmazas != null ? itemInfo.szarmazas : "nincs megadva"}
                            </div>
                        </div>
                        <div className="others">
                            <div className="title">Tápérték adatok</div>
                            <div className="content">
                                Energia Kj: {itemInfo.energiakj != null ? itemInfo.energiakj : "nincs megadva"}<br/>
                                Energia Kcal: {itemInfo.energiakcal != null ? itemInfo.energiakcal : "nincs megadva"}<br/>
                                Zsír: {itemInfo.zsir != null ? itemInfo.zsir : "nincs megadva"}<br/>
                                Telitett zsírsav: {itemInfo.tzsir != null ? itemInfo.tzsir : "nincs megadva"}<br/>
                                Szénhidrát: {itemInfo.szenhidrat != null ? itemInfo.szenhidrat : "nincs megadva"}<br/>
                                Cukrok: {itemInfo.cukrok != null ? itemInfo.cukrok : "nincs megadva"}<br/>
                                Rost: {itemInfo.rost != null ? itemInfo.rost : "nincs megadva"}<br/>
                                Fehérje: {itemInfo.feherje != null ? itemInfo.feherje : "nincs megadva"}<br/>
                                Só: {itemInfo.so != null ? itemInfo.so : "nincs megadva"}<br/>
                            </div>
                        </div>
                        <div className="logisticInfo">
                            <div className="title">Logisztikai adatok</div>
                            <div className="content">
                                Súly: {itemInfo.suly != null ? itemInfo.suly+"g" : "nincs megadva"}<br/>
                                Méret: {itemInfo.meret != null ? itemInfo.meret : "nincs megadva"}<br/>
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        } else return null
    }
}
SelectedItem.contextType = MainContext
export default SelectedItem
