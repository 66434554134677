import React, { Component } from 'react'
import FullPageCarousel from '../../../components/FullPageCarousel'

class PageType2 extends Component {
    
    render() {
        return(
            <div style={{height: "100%"}}>
                <FullPageCarousel items={this.props.content.carouselItems}/>
            </div>
        );
    }
}
export default PageType2;