// - Order/CategoriesItem.js

import React, { Component } from 'react'
import {NavLink} from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

class CategoriesItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: this.props.selectedCategory === this.props.id ? " selected" : ""
    }
  }
  componentDidUpdate(oldProps) {
    const newProps = this.props
    if (oldProps.selectedCategory !== newProps.selectedCategory) {
      if(this.props.selectedCategory === this.props.id) this.setState({selected : " selected"})
      else this.setState({selected: ""})
    }
  }
  render() {
      /*let img
      img = LoadAssets(this.props.catStyle.appImage, this.props.assets)
      if(img === this.props.endpoint+"/undefined") img = this.props.endpoint+"/assets/img/brand/default_categ.png"
      let delay = this.props.delay < 9 ? "0."+this.props.delay+"s" : this.props.delay/10+"s"*/
      //let color = this.state.selected === " selected" ? "secondary" : "primary"
      return (
        <NavLink to={"/order/orderitems/"+this.props.id}>
          <div className="categoryItems"><p>{this.props.name}</p><ArrowForwardIosIcon/></div>
        </NavLink>
      );
    }
  }
  export default CategoriesItem;
  