import React, { Component } from 'react'
import BasketItem from './BasketItem'
import Button from '@material-ui/core/Button'
import {NavLink} from 'react-router-dom'

class Basket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: null,
            totalPrice: null,
            totalWeight: null,
            deliveryCost: null
        }
    }
    calcTotalPrice = (items) => {
        let totalPrice = 0;
        for (let index = 0; index < items.length; index++) {
            if(!items[index].notAvailable) totalPrice += items[index].count*items[index].price;
        }
        return totalPrice;
    }
    calcTotalWeight = (items) => {
        let totalWeight = 0;
        for (let index = 0; index < items.length; index++) {
            let weight = JSON.parse(items[index].additional_info).suly == null ? "0" : JSON.parse(items[index].additional_info).suly 
            if(!items[index].notAvailable) totalWeight += items[index].count*weight.match(/\d+/g).map(Number)[0];
        }
        return totalWeight;
    }
    getModifiersArray = (item) => {
        let modifiers = []
        item.modifier_groups.map(
            (group, groupIndex) => {
                return(
                    group.modifiers.map(
                        modifier => {
                            if(modifier.count > 0){
                                let modifierToSubmit
                                modifierToSubmit = {
                                    uuid: modifier.uuid,
                                    count: modifier.count,
                                    price: parseInt(modifier.price),
                                    modifierGroupUUID: item.modifier_groups[groupIndex].uuid
                                }
                                modifiers.push(modifierToSubmit)
                            }
                            return true
                        }
                    )
                )
            }
        )
        return modifiers
    }
    renderItems = () =>{
        if(this.state.items !== null) {
            let cartModalItems = this.state.items.map(
                (item, index) => {
                    return(<BasketItem 
                                key={item.uuid} 
                                item={item} 
                                selectedLanguage={this.props.selectedLanguage}
                                assets={this.props.assets}
                                endpoint={this.props.endpoint}
                                currencies={this.props.currencies}
                                addClass={this.props.addClass}
                                setOrderItemCount={this.props.setOrderItemCount}
                                itemView={this.props.itemView}
                            />
                    )
                }
            )
            return cartModalItems
        }
    }
    setItems = async () => {
        let items = []
        let itemsToSubmit = []
        if(this.props.orderItemTree !== null) {
            this.props.orderItemTree.map(
                (categ, categIndex) => {
                    return(
                        categ.items.map(
                            (item, itemIndex) => {
                                if(item.count > 0){
                                    if(!this.props.isItemVisible(item.uuid)) {
                                        item.notAvailable = true
                                        item.categIndex = categIndex
                                        item.itemIndex = itemIndex
                                        items.push(item)
                                        this.setState({
                                            notAvailableDesc: this.props.dictionary['app_cart_notAvailableDesc'].translation[this.props.selectedLanguage]
                                        })
                                    }
                                    else {
                                        item.notAvailable = false
                                        item.categIndex = categIndex
                                        item.itemIndex = itemIndex
                                        items.push(item)
                                        let modifiers = this.getModifiersArray(item)
                                        let newItem
                                        newItem = {
                                            uuid: item.uuid,
                                            count: item.count,
                                            price: item.price,
                                            vat: item.vat,
                                            selectedItemTakeAway: 0,
                                            selectedItemSize: item.selectedItemSize,
                                            modifiers: modifiers,
                                            categoryUUID: this.props.orderItemTree[categIndex].uuid
                                        }
                                        itemsToSubmit.push(newItem)
                                    }
                                }
                                return true
                            }
                        )
                    )
                }
            )
            await this.setState({
                items: items,
                totalPrice: this.calcTotalPrice(items),
                totalWeight: this.calcTotalWeight(items)
            })
            this.refreshDeliveryCost(this.state.totalWeight)
            this.props.setItemsToSubmit(itemsToSubmit)
        }
    }
    refreshDeliveryCost = async (weight) => {
        let deliveryCost = await this.props.getDeliveryCost(weight)
        this.setState({ deliveryCost: deliveryCost.royalFutar })
        if(this.props.deliveryType === "delivery") {
            this.setState({ totalPrice: this.state.totalPrice + deliveryCost.royalFutar })
            if(typeof this.props.setDeliveryCost === "function") this.props.setDeliveryCost(deliveryCost.royalFutar)
        } else {
            if(typeof this.props.setDeliveryCost === "function") this.props.setDeliveryCost(0)
        }
    }    
    componentDidUpdate(oldProps) {
        if(oldProps.orderItemTree !== this.props.orderItemTree) this.setItems()
    }
    componentDidMount() {
        this.setItems()
    }
    render() {
        let currencies = this.props.currencies
        let selectedLanguage = this.props.selectedLanguage
        if(currencies !== null && selectedLanguage !== null) {
            if(this.state.items !== null && this.state.items.length > 0) {
                return(
                    <div className={"basket hidden appear "+this.props.addClass}>
                        <div className="basketCard">
                            <div className="header">
                                <div className="itemName">Név</div>
                                <div className="itemQuantity">Mennyiség</div>
                                <div className="itemPrice">Egységár</div>
                                <div className="itemSumPrice">Ár összesen</div>
                            </div>
                            <div className="basketItems">
                                {this.renderItems()}
                            </div>
                            {this.props.deliveryType === "delivery" && <div className="deliveryInfo">
                                {"Termékek súlya összesen: "+this.state.totalWeight/1000+"kg, szállítási költség: "+this.state.deliveryCost+" Ft"}
                            </div>}
                            <div className="sumTotalPrice">
                                <div className="text">Összesen:</div>
                                <div className="price">{this.state.totalPrice}</div>
                                <div className="currency">{currencies.huf[selectedLanguage]}</div>
                            </div>
                        </div>
                        <div className="actions">
                            <Button className="resetOrder" variant="contained" color="secondary" onClick={this.props.resetOrderItemtree}>Kosár ürítése</Button>
                            <NavLink to="/order/basket">
                                <Button 
                                    className="finalizeOrder" 
                                    variant="contained" 
                                    color="primary"
                                    onClick={this.props.closePopover}
                                >
                                    Tovább a kosaramhoz
                                </Button>
                            </NavLink>
                            <NavLink to="/order/orderitems">
                                <Button className="continueShopping" variant="contained" color="primary">
                                    Folytatom a vásárlást
                                </Button>
                            </NavLink>
                        </div>
                    </div>
                )
            } else return (
                <div className="emptyBasket">
                    <div className="text">A kosarad üres.</div>
                    <NavLink to="/order/orderitems">
                        <Button className="continueShopping" variant="contained" color="primary">
                            Folytatom a vásárlást
                        </Button>
                    </NavLink>
                </div>
            )
        } else return null
    }
}
export default Basket;