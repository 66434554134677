import React, { Component } from 'react'
import LoadAssets from '../../../components/LoadAssets'

class PageType3 extends Component {
    
    render() {
        return(
            <div className="blockContainer">
                {this.props.content.blocks.map(block => 
                    <div key={block.id}  className="blockItem" style={{backgroundImage: "url('"+LoadAssets(block.image, this.props.assets)+"')", backgroundRepeat: "no-repeat", backgroundSize: "cover"}}>
                        <div className="title">{block.title}</div>
                    </div>
                )}
            </div>
        );
    }
}
export default PageType3;