import React, { Component } from 'react'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'

class CategoriesSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
       selectedCategoryIDs: []
    }
  }
  handleChange = (event) => {
    this.setState({
      selectedCategoryIDs: event.target.value
    })
    this.props.setSelectedCategories(event.target.value)
    localStorage['selectedCategories'] = event.target.value
  }
  componentDidMount() {
    if(localStorage['selectedCategories'] !== undefined && localStorage['selectedCategories'] !== "") {
      this.setState({
        selectedCategoryIDs: localStorage['selectedCategories'].split(',').map(Number)
      })
    }
  }
  render() {
      if(this.props.categories.length !== 0) {
        return (
          <div className="categorySelect">
            <FormControl>
              <InputLabel id={"category-label"}>Kategóriák</InputLabel>
              <Select
                    id="mutiple-checkbox"
                    labelId={"category-label"}
                    multiple
                    value={this.state.selectedCategoryIDs}
                    onChange={this.handleChange}
                    
                    renderValue={(selected) => {
                        if(selected.length > 1) {
                            return "Több kiválasztva"
                        } else return (this.props.categories[selected[0]].name)
                    }}
                >
                {this.props.categories.map((category) => (
                    <MenuItem key={category.name} value={category.index}>
                        <Checkbox checked={this.state.selectedCategoryIDs.indexOf(category.index) > -1} />
                        <ListItemText primary={category.name} />
                    </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )
      } else return null
    }
  }
  export default CategoriesSelect;
  