import React, { Component } from 'react';
import CategoriesItem from './CategoriesItem'
import CategoriesSelect from './CategoriesSelect'
import {withRouter} from 'react-router-dom'
import SearchBar from '../../../components/Order/SearchBar'
import {NavLink} from 'react-router-dom'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'

class Categories extends Component {
  setSelectedCategories = (e) => {
    let categoryString = ""
    for(let i=0; i<e.length; i++) {
      categoryString += e[i] + "-"
    }
    categoryString = categoryString.substring(0, categoryString.length - 1)
    this.props.history.replace("/order/orderitems/"+categoryString)
  }
  renderItem = (index) => {
    let orderItemTree = this.props.orderItemTree
    let selectedLanguage = this.props.selectedLanguage
    let name = JSON.parse(orderItemTree[index].name)[selectedLanguage];
    let assets = this.props.assets
    return <CategoriesItem 
              name={name} 
              catStyle={JSON.parse(orderItemTree[index].attributes)} 
              key={orderItemTree[index].uuid} 
              id={index} 
              onSelectCategory={this.onSelectCategory}
              assets={assets}
              endpoint={this.props.endpoint}
            />;
  }

  renderItems = (items) => {

    let orderItemTree = this.props.orderItemTree
    if(orderItemTree !== null) {
        const listItems = orderItemTree.map((item, index) => (this.renderItem(index, '')))
        return <React.Fragment>{listItems}</React.Fragment>;
    } else return null
  }

  render() {
    let minimized = this.props.minimized === true ? " minimized" : ""
    let categories = []
    if(this.props.orderItemTree !== null && this.props.orderItemTree.length > 1) {
      categories = this.props.orderItemTree.map((item, index) => {
        return({
          index: index, 
          name:JSON.parse(item.name)[this.props.selectedLanguage]
        })
      })
      return (
        <div className={"categoriesContainer hidden appear"+minimized}>  
              {this.props.searchBar === true && 
                <SearchBar 
                  setSearchedItems={this.props.setSearchedItems} 
                  getItemNames={this.props.getItemNames} 
                  replaceCharacters={this.props.replaceCharacters}
                  getHighlightedText={this.props.getHighlightedText}
                />}
              {!this.props.minimized === true && <NavLink to={"/order/orderitems/all"}>
                <div className="categoryItems"><p>Összes termék</p><ArrowForwardIosIcon/></div>
              </NavLink>}
              {!this.props.minimized === true && this.renderItems()}
              {this.props.minimized && 
                <CategoriesSelect 
                  categories={categories} 
                  setSelectedCategories={this.setSelectedCategories} 
                />}
        </div>
      )
    } else return null
  }
}
export default withRouter(Categories);
  