import React, { Component } from "react"
import { Popover } from "@material-ui/core"
import AccountBoxIcon from "@material-ui/icons/AccountBox"
import LoginType from "../Login/LoginType"
import Button from "@material-ui/core/Button"
import { NavLink } from "react-router-dom"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import TabPanel from './TabPanel'
import RegisterUser from "../Login/RegisterUser"


class LoginIcon extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: null,
      userLoggedIn: this.props.userLoggedIn,
      tabValue: 0
    };
  }
  handleTabChange = (event, newValue) => {
    this.setState({
      tabValue: newValue
    })
  }
  handleClick = (event) => {
    this.setState({
      anchorEl: event.currentTarget,
    });
  };
  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };
  renderContent = () => {
    if(this.state.userLoggedIn) {
      return(
        <React.Fragment>
          <NavLink to="/profile">
            <Button variant="contained" color="primary">
              Adataim
            </Button>
          </NavLink>
          <Button
            variant="contained"
            color="secondary"
            onClick={this.props.userLoggedOut}
          >
            kilépés
          </Button>
        </React.Fragment>
      )
    } else {
      return(
        <React.Fragment>
          <Tabs
            value={this.state.tabValue}
            onChange={this.handleTabChange}
            aria-label="simple tabs example"
          >
            <Tab label="Belépés" />
            <Tab label="Regisztráció" />}
          </Tabs>        
          <TabPanel value={this.state.tabValue} index={0}>
            <LoginType
              validateUserPin={this.props.validateUserPin}
              generateUserPin={this.props.generateUserPin}
              userLoginWithCredentials={this.props.userLoginWithCredentials}
            />
          </TabPanel>
          <TabPanel value={this.state.tabValue} index={1}>
            <RegisterUser
              validateUserPin={this.props.validateUserPin}
              generateUserPin={this.props.generateUserPin}
            />
          </TabPanel>
        </React.Fragment>
      ) 
    }
  }
  componentDidUpdate(oldProps) {
    if (oldProps.userLoggedIn !== this.props.userLoggedIn)
      this.setState({
        userLoggedIn: this.props.userLoggedIn,
      });
  }
  render() {
    return (
      <div className="loginIconContainer">
        <AccountBoxIcon onClick={this.handleClick} />
        <Popover
          id={"login-popover"}
          open={this.state.anchorEl !== null}
          anchorEl={this.state.anchorEl}
          onClose={this.handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className="loginPopover">
            {this.renderContent()}
          </div>
        </Popover>
      </div>
    );
  }
}
export default LoginIcon;
