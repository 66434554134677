import React, { Component } from 'react';
import MinisiteContext from '../MinisiteContext';
import MenuItem from './MenuItem';

class Menu extends Component {
    renderMenu = () => {
        return this.props.content.filter(page => page.onNavbar).map((item, index) => <MenuItem key={index} name={item.name} itemID={item.id} />)
    }
    handlehomeIconClick = () => {
        this.context.handlePageChange(0)
    }
    render() {
        return(
            <div className={"menuBar"}>
                <div className="leftContent" onClick={this.handlehomeIconClick}>
                   <img id="menuIconImg" alt="" src="/assets/img/brand/steg_logo_szines.png" />                
                </div>
                <div className="menuItems">
                    {this.renderMenu()}                  
                </div>
                <div className="rightContent">
                    <a href="https://www.instagram.com/steg8237_tihany" target="_blank" rel="noopener noreferrer" ><img id="menuInstaImg" alt="" src="/assets/img/brand/logo_insta.png" /></a>
                    <a href="https://www.facebook.com/steg8237" target="_blank" rel="noopener noreferrer" ><img id="menuFaceImg" alt="" src="/assets/img/brand/logo_facebook.png" /></a>
                    {/*<img id="menuWeatherImg" alt="" src="/assets/img/brand/logo_weather.png" />*/}
                </div>
            </div>
        );
    }
}

Menu.contextType = MinisiteContext;
export default Menu;