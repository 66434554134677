import React, { Component } from 'react'

class PageType4 extends Component {
    
    render() {
        return(
            <div className="contactsContent">
                <div className="textBox">
                    <div className="header">
                        {this.props.content.textBox.header}
                    </div>
                    <div className="body">
                        {this.props.content.textBox.body.map((item, index) => 
                            <div key={index} className="listItem"><i className="material-icons icon">{item.icon}</i><span>{item.text}</span></div>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
export default PageType4;