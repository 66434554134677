import React, { Component } from 'react'
import CartIcon from './CartIcon'
//import Login from '../Login'
import LoginIcon from './LoginIcon'
import Language from './Language'
import { NavLink } from 'react-router-dom'

class TopBar extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            userLoggedIn: this.props.userLoggedIn,
        }
    }
    componentDidUpdate(oldProps) {
        if(oldProps.userLoggedIn !== this.props.userLoggedIn)
        this.setState({
            userLoggedIn: this.props.userLoggedIn
        })
    }
    render() {
        return(
            <div id="topBar">
                <NavLink to="/">
                    <img src={this.props.endpoint+"/assets/img/brand/logo_minimized.png"} alt="brandLogo" />
                </NavLink>
                <CartIcon 
                    selectedItemCount={this.props.selectedItemCount} 
                    orderItemTree={this.props.orderItemTree}
                    isItemVisible={this.props.isItemVisible}
                    selectedLanguage={this.props.selectedLanguage}
                    dictionary={this.props.dictionary}
                    assets={this.props.assets}
                    endpoint={this.props.endpoint}
                    currencies={this.props.currencies}
                    resetOrderItemtree={this.props.resetOrderItemtree}
                    onClickBasketItem={this.props.onClickBasketItem}
                    setOrderItemCount={this.props.setOrderItemCount}
                    setItemsToSubmit={this.props.setItemsToSubmit}
                    getDeliveryCost={this.props.getDeliveryCost}
                    itemView={this.props.itemView}
                />
                {/*<Login
                    client={this.props.client}
                    onLoginStatusChange={this.props.onLoginStatusChange}
                    validateUserPin={this.props.validateUserPin}
                    generateUserPin={this.props.generateUserPin}
                />*/}
                <LoginIcon 
                    validateUserPin={this.props.validateUserPin}
                    generateUserPin={this.props.generateUserPin}
                    userLoggedIn={this.state.userLoggedIn}
                    userLoggedOut={this.props.userLoggedOut}
                    userLoginWithCredentials={this.props.userLoginWithCredentials}
                />
                <Language 
                    endpoint={this.props.endpoint}
                    setLang={this.props.setLang}
                />
            </div>
        )
    }
}
export default TopBar