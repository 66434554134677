import React, { Component } from 'react'
import Success from './Success'
import Failed from './Failed'
import { css } from '@emotion/core'
import { FadeLoader } from 'react-spinners'
import MainContext from '../../Webshop/MainContext'

const override = css`
    display: block;
    margin: 0 auto;
    margin-top: 20vh;
`;

class Backref extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            loading: true,
            orderRef: null,
            transactionID: null,
            title: "",
            success: null,
            status: null
        }
    }
    componentDidMount() {
        this.context.setSearchVisible(false)
        let url = this.props.location.search.substr(1)
        let array = []
        for(let param of url.split("&")) {
            let aaa = param.split("=")
            array[aaa[0]] = aaa[1]
        }
        //console.log(array['order_ref'])
        if(array['pay_at_store'] === "true") {
            this.setState({
                loading: false,
                transactionID: -1,
                success: true
            })
        } else {
            this.setState({
                orderRef: array['order_ref']
            })
            setTimeout(this.checkOrderPayment, 1000);
        }       
    }
    checkOrderPayment = async () => {
        if(this.state.orderRef !== null) {
          const order = await this.context.checkOrderPayment(this.state.orderRef)
          //console.log(order)
          switch(order.ORDER_STATUS) {
            case "PAYMENT_AUTHORIZED":
              this.setState({
                loading: false,
                success: true,
                transactionID: order.REFNO,
              })
              break
            case "COMPLETE":
                this.setState({
                    loading: false,
                    success: true,
                    transactionID: order.REFNO,
                })
            break
            case "SUCCESS":
                this.setState({
                    loading: false,
                    success: true,
                    transactionID: order.REFNO,
                })
            break
            case "CARD_NOTAUTHORIZED":
                this.setState({
                    loading: false,
                    success: false,
                    transactionID: order.REFNO,
                    status: "CARD_NOTAUTHORIZED"
                })
            break
            case "FAIL":
                this.setState({
                    loading: false,
                    success: false,
                    transactionID: order.REFNO,
                    status: "FAIL"
                })
            break
            case "CANCEL":
                this.setState({
                    loading: false,
                    success: false,
                    transactionID: order.REFNO,
                    status: "CANCEL"
                })
            break
            case "TIMEOUT":
                this.setState({
                    loading: false,
                    success: false,
                    transactionID: order.REFNO,
                    status: "TIMEOUT"
                })
            break
            case "IN_PROGRESS":
              setTimeout(this.checkOrderPayment, 2000);
              break
            default:
              this.setState({
                loading: false,
                success: false,
                status: order.ORDER_STATUS
              })
              break
          }
        }
    }
    render() {
        return (
            <div className="orderBackref">
                <FadeLoader
                css={override}
                color={this.context.loaderColor}
                loading={this.state.loading}
                />
                {!this.state.loading && this.state.success &&
                    <Success 
                        transactionID={this.state.transactionID} 
                        userLoggedIn={this.context.loggedIn}
                    />}
                {!this.state.loading && !this.state.success && <Failed transactionID={this.state.transactionID} status={this.state.status}/>}
            </div>
        )
    }
}
Backref.contextType = MainContext
export default Backref