import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'

class SubmitSuccess extends Component {
    componentDidMount() {
        //console.log(this.props.paymentType)
        if(this.props.paymentType === "simplePay") {
            setTimeout(this.redirectToSimplePay, 3000)
        } else {
            setTimeout(this.redirectToBackref, 3000)
        }
        //setTimeout()
    }
    redirectToSimplePay = () => {
        this.props.history.replace("/order/startpayment")
    }
    redirectToBackref = () => {
        this.props.history.replace("/order/backref?pay_at_store=true")
    }
    render() {
        return (
            <div className="submitSuccessContainer hidden appear">
                {this.props.paymentType === "simplePay" && "Sikeres rendelés, most továbbirányítunk a fizetéshez."}
                {this.props.paymentType !== "simplePay" && "Sikeres rendelés."}
            </div>
        )
    }
}
export default withRouter(SubmitSuccess)
  