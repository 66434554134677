import React, { Component } from "react"
import { NavLink } from 'react-router-dom'

class Footer extends Component {
 
  render() {
    return (
        <div className="footer">
          <div className="left">@Minden jog fenntartva • 2020</div>
          <div className="mid">
            {/* STÉG • 8237 */}
            {/* Törzshely */}
          </div>
          <div className="right">
            {/*<NavLink to="/contacts"><div className="contact">Kapcsolat</div></NavLink>*/}
            <a href={this.props.endpoint+'/assets/docs/brand/terms.pdf'} target="_blank" rel="noopener noreferrer"><div className="terms">ÁSZF</div></a>
            <a href={this.props.endpoint+'/assets/docs/brand/policy.pdf'} target="_blank" rel="noopener noreferrer"><div className="privacy">Adatkezelés</div></a>
            {this.props.delivery && <NavLink to="/deliveryinfo"><div className="deliveryInfo">Szállítási információk</div></NavLink>}
          </div>
        </div>
    );
  }
}
export default Footer;