import React, { Component } from 'react'
import LoadAssets from '../../LoadAssets'
import { NavLink } from 'react-router-dom'

import CountButton from '../../CountButton'

import {ReactComponent as CircleTrashIconActive} from "../../../svg/base/circleTrashIconActive.svg"
import {ReactComponent as CircleMinusIconActive} from "../../../svg/base/circleMinusIconActive.svg"
import {ReactComponent as CirclePlusIconActive} from "../../../svg/base/circlePlusIconActive.svg"
import {ReactComponent as CirclePlusIconPassive} from "../../../svg/base/circlePlusIconPassive.svg"
import {ReactComponent as PlusMinusFieldBg} from "../../../svg/base/plusMinusFieldBg.svg"
import {ReactComponent as CircleMinusIconPassive} from "../../../svg/base/circleMinusIconPassive.svg"

class BasketItem extends Component {
    onChangeCount = (e) => {
        this.props.setOrderItemCount(this.props.item.categIndex, this.props.item.itemIndex, e.count)
    }
    onAddItem = () => {
        this.props.setOrderItemCount(this.props.item.categIndex, this.props.item.itemIndex, 1)
    }
    removeItem = () => {
        this.props.setOrderItemCount(this.props.item.categIndex, this.props.item.itemIndex, 0)
    }
    render() {
        let itemImageUrl = LoadAssets(JSON.parse(this.props.item.attributes).appImage, this.props.assets)
        if(itemImageUrl === this.props.endpoint+"/undefined") itemImageUrl = this.props.endpoint+"/assets/img/brand/default_item.png"
        let addClass = ""
        if(this.props.addClass) addClass = this.props.addClass
            return(
                <div className={"basketItemContainer "+addClass}>
                    <div className="itemImg">
                        <img src={itemImageUrl} alt="itemImg" />
                    </div>
                    <div className="itemName">
                        {this.props.itemView && 
                        <NavLink to={"/order/orderitems/"+this.props.item.categIndex+"/0/"+this.props.item.itemIndex}>
                            <div className="text">
                                <p>{JSON.parse(this.props.item.name)[this.props.selectedLanguage]}</p>
                            </div>
                        </NavLink>}
                        {!this.props.itemView &&                         
                            <div className="text">
                                <p>{JSON.parse(this.props.item.name)[this.props.selectedLanguage]}</p>
                            </div>}
                    </div>
                    <div className="itemCount">
                        <p>{this.props.item.count} </p>
                    </div>
                    <div className="itemPrice">
                        <div className="price">
                            <p>{this.props.item.price}</p>
                        </div>
                        <div className="currency">
                            <p>{this.props.currencies.huf[this.props.selectedLanguage]}</p>
                        </div>
                    </div>
                    <div className="itemTotalPrice">
                        <div className="totalPrice">
                            <p>{this.props.item.price*this.props.item.count}</p>
                        </div>
                        <div className="currency">
                            <p>{this.props.currencies.huf[this.props.selectedLanguage]}</p>
                        </div>
                    </div>
                    <div className="countButton">
                        <CountButton 
                            minValue='0'
                            maxValue='2'
                            count={this.props.item.count}
                            onChange={this.onChangeCount}
                            onAddItem={this.onAddItem} 
                            containerBgImage={<PlusMinusFieldBg />}
                            plusImage={<CirclePlusIconPassive />} 
                            plusActiveImage={<CirclePlusIconActive />} 
                            minusImage={<CircleMinusIconPassive />} 
                            minusActiveImage={<CircleMinusIconActive />} 
                            oneLeftImage={<CircleMinusIconPassive />}
                            oneLeftActiveImage={<CircleTrashIconActive />}
                        />
                    </div>
                    <div className="removeItem">
                        <CircleTrashIconActive onClick={this.removeItem}/>
                    </div>
                </div>
            )
    }
}
export default BasketItem;