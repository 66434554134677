import React, {Component} from 'react'
import {NavLink} from 'react-router-dom'

class Breadcrumbs extends Component {
    render() {
        return (
            <div className="breadCrumbs">
                <span className="categoryName">
                    <NavLink to={"/order/orderitems/"+this.props.categoryId}>{this.props.categoryName}</NavLink>
                </span>
                <span className="separator">/</span>
                <span className="itemName">
                    {this.props.itemName}
                </span>
            </div>
        )
    }
}
export default Breadcrumbs;
