import React, { Component } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

class RegisterUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: localStorage["loginEmailSent"] === "true",
      userEmail:
        localStorage["userEmail"] !== undefined
          ? localStorage["userEmail"]
          : "",
      pin: "",
      submitingForm: false,
      loginType: "pin"
    };
  }
  modifyEmail = () => {
    localStorage.removeItem("userEmail");
    localStorage.removeItem("loginEmailSent");
    this.setState({
      userEmail: "",
      emailSent: false,
    });
  };
  handleEmailChange = (event) => {
    this.setState({ userEmail: event.target.value });
  };
  handleEmailFormSubmit = async (event) => {
    event.preventDefault();
    if (
      this.state.userEmail.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
    ) {
     
      let response = await this.props.generateUserPin(this.state.userEmail);
      if (response) {
        localStorage["userEmail"] = this.state.userEmail;
        localStorage["loginEmailSent"] = true;
      }
      this.setState({
        emailSent: response,
      });
    } else this.notify();
  };
  notifyEmail = () => toast.warn("Hibás email cím formátum!");
  notifySuccess = () => toast.success("Sikeres regisztráció!");
  notifyWrongPin = () => toast.error("Sikertelen azonosítás!");
  notifyPinFormat = () =>
    toast.warn("Hibás pin formátum! (4 számjegy 0-9 között)");
  handlePinChange = (event) => {
    let value = event.target.value;
    if (value.match(/^[0-9]*$/) !== null) {
      if (parseInt(value) > 9999) value = 9999;
      if (value.length > 4) value = value.substring(0, 4);
      this.setState({ pin: value });
    }
  };
  handlePinFormSubmit = async (event) => {
    event.preventDefault();
    this.setState({
      submitingForm: true,
    });
    if (this.state.pin.match("^[0-9]{4}$")) {
      let response = await this.props.validateUserPin(
        this.state.userEmail,
        this.state.pin
      );
      if (response.success) {
        this.notifySuccess();
      } else {
        this.notifyWrongPin();
      }
    } else this.notifyPinFormat();
    setTimeout(() => {
      this.setState({
        submitingForm: false,
      });
    }, 2000);
  };
  renderEmailFormContent = () => {
    if(this.state.emailSent) {
      return(
        <React.Fragment>
          <TextField
              id="email"
              name="email"
              label={"E-mail cím"}
              value={this.state.userEmail}
              onChange={this.handleEmailChange}
              margin="normal"
              type="email"
              disabled={true}
              required
          />
          <Button
            variant="contained"
            color="secondary"
            onClick={this.modifyEmail}
          >
            email cím módosítása
          </Button>
        </React.Fragment>
      )
    } else {
      return(
        <React.Fragment>
          <p className="hidden appear">Regisztrációhoz add meg az email címed!</p>
          <TextField
            id="email"
            name="email"
            label={"E-mail cím"}
            value={this.state.userEmail}
            onChange={this.handleEmailChange}
            margin="normal"
            type="email"
            required
          />
          <Button type="submit" name="loginPin" color="primary" variant="contained" onClick={() => {
            localStorage["loginType"] = "pin"
            this.setState({loginType: "pin"})}}
          >
            elküld
          </Button>
        </React.Fragment>
      )
    }
  }
  renderLoginForm = () => {
    return(
      <form
        className="pinForm hidden appear"
        onSubmit={this.handlePinFormSubmit}
      >
        <p className="hidden appear">
          A regisztráció véglegesítéséhez szükséges PIN kódot elküldtük a megadott email címre.
        </p>
        <TextField
          id="pin"
          name="pin"
          label={"PIN kód"}
          value={this.state.pin}
          onChange={this.handlePinChange}
          margin="normal"
          type="number"
          max="9999"
          required
        />
        <Button
          type="submit"
          color="primary"
          variant="contained"
          disabled={this.state.submitingForm}
        >
          regisztrál
        </Button>
      </form>
    )
  }
  render() {
    return (
      <div className="registerEmailFormContainer">
        <form
          className="emailForm hidden quickAppear"
          onSubmit={this.handleEmailFormSubmit}
        >
          {this.renderEmailFormContent()}
        </form>
        {this.state.emailSent && this.renderLoginForm()}
      </div>
    );
  }
}

export default RegisterUser;
