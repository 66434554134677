import React, { Component } from 'react'
//import Button from '@material-ui/core/Button'
//import {NavLink} from 'react-router-dom'


class Success extends Component {
    componentDidMount() {
        let orderNumber = localStorage['lastOrderNumber']
        let orderUUID = localStorage['orderUUID']
        if(!this.props.userLoggedIn) localStorage.clear()
        localStorage['lastOrderNumber'] = orderNumber
        localStorage['orderUUID'] = orderUUID
    }
    render() {
        return (
            <div className="successContainer hidden appear">
                <div className="message">
                {this.props.transactionID !== null && this.props.transactionID !== -1 && <h3>Sikeres tranzakció.</h3>}
                {this.props.transactionID !== null && this.props.transactionID !== -1 && <p>{"SimplePay tranzakció azonosító: " + this.props.transactionID}</p>}
                </div>
                <p>Köszönjük a vásárlásod, örülünk, hogy a tagjaink között köszönthetünk! Reméljük hamarosan találkozunk a stégen.</p>
                {/*<div className="orderNumber">
                    <div className="text hidden appear" style={{animationDelay: "0.5s"}}>
                        Rendelésszám:
                    </div>
                    <div className="number hidden appear" style={{animationDelay: "1s"}}>
                        {localStorage['lastOrderNumber']}
                    </div>
                </div>
                <div className="footerText">
                    Köszönjük a vásárlást,<br/>
                    Törzshely<br/>
                    Ferihegyi út 26, Budapest, 1172<br/>
                    Nyitvatartás: H-SZ 07:00-22:00, V 08:00-20:00<br/>
                    Telefon: +36 30 195 1283
                </div>
                <NavLink to="/order/orderitems">
                    <Button className="continueShopping" variant="contained" color="primary">
                        Új rendelést adok le
                    </Button>
                </NavLink>*/}

            </div>
        )
  }
}
export default Success
  