import React, { Component } from 'react'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary  from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails  from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Address from '../../../components/Profile/Address'

class DeliveryDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded
    }
  }
  setExpanded = (e) => {
    this.setState({
      expanded: e
    })
  }
  handlePanelChange = panel => (event, isExpanded) => {
    this.props.setInvoiceDeliverySameData(!isExpanded)
  }
  componentDidUpdate(oldProps) {
    if(oldProps.expanded !== this.props.expanded) {
      this.setExpanded(this.props.expanded)
    }
  }
  render() {
    return (
      <MuiExpansionPanel 
        square={true}
        className={"hidden appear"} 
        expanded={this.state.expanded}
        onChange={this.handlePanelChange('panel2')}
      >
        <MuiExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color={"inherit"}/>}
          aria-controls={"panel-a-content"}
          id={"panel-a-header"}
        >       
          Szállítási adatok
        </MuiExpansionPanelSummary>
        <MuiExpansionPanelDetails>
      <div className="newAddress">
        <TextField 
            className="name" 
            id="name"
            name="name"
            label={this.props.dictionary['app_order_nameOrCompany'].translation[this.props.selectedLanguage]}
            error={this.props.errors.name && this.props.touched.name}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.name}
            helperText={this.props.errors.name}
            variant="filled"
        />
        <TextField 
            id="telNumber"
            name="telNumber"
            className="phone" 
            label="Telefonszám"
            error={this.props.errors.telNumber && this.props.touched.telNumber}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.telNumber}
            helperText={this.props.errors.telNumber}
            variant="filled"
        />
        <Address
          id={"deliveryAddress"}
          name={"deliveryAddress"}
          value={this.props.values.deliveryAddress}
          onChange={this.props.handleChange}
          onBlur={this.props.handleBlur}
          dictionary={this.props.dictionary}
          selectedLanguage={this.props.selectedLanguage}
          endpoint={this.props.endpoint}      
        />
        <TextField 
            id="notes"
            name="notes"
            className="notes" 
            label="Megjegyzés"
            error={this.props.errors.notes && this.props.touched.notes} 
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            helperText={this.props.errors.notes}
            value={this.props.values.notes}
            variant="filled"
        />
      </div>
      </MuiExpansionPanelDetails>
      </MuiExpansionPanel>
    )
  }
}
export default DeliveryDetails


/*
<TextField 
            className="zip" 
            id="zip"
            name="zip"
            label={this.props.dictionary['app_order_zip'][this.props.selectedLanguage]}
            error={this.props.errors.zip && this.props.touched.zip}
            type="number" 
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.zip}
            helperText={this.props.errors.zip}
            variant="filled"
        />
        <TextField 
            className="state" 
            id="state"
            name="state"
            label={"Megye"}
            error={this.props.errors.state && this.props.touched.state}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.state}
            helperText={this.props.errors.state}
            variant="filled"
        />
        <TextField 
            className="city" 
            id="city"
            name="city"
            label={this.props.dictionary['app_order_city'][this.props.selectedLanguage]}
            error={this.props.errors.city && this.props.touched.city}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.city}
            helperText={this.props.errors.city}
            variant="filled"
        />
        <TextField 
            className="street" 
            id="street"
            name="street"
            label={"Utca"}
            error={this.props.errors.street && this.props.touched.street}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.street}
            helperText={this.props.errors.street}
            variant="filled"
        />
         <TextField 
            className="streetNumber" 
            id="streetNumber"
            name="streetNumber"
            label={"Házszám"}
            error={this.props.errors.streetNumber && this.props.touched.streetNumber}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.streetNumber}
            helperText={this.props.errors.streetNumber}
            variant="filled"
        />
         <TextField 
            className="floor" 
            id="floor"
            name="floor"
            label={"Emelet, ajtó"}
            error={this.props.errors.floor && this.props.touched.floor}
            onChange={this.props.handleChange}
            onBlur={this.props.handleBlur}
            value={this.props.values.floor}
            helperText={this.props.errors.floor}
            variant="filled"
        />
*/