import React, { Component } from 'react'
//import InputBase from '@material-ui/core/InputBase'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import {withRouter} from 'react-router-dom'
import Autosuggest from 'react-autosuggest'

class SearchBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            suggestions: []
        }
    }
    getSuggestions = value => {
        const itemNames = this.props.getItemNames()
        const inputValue = this.props.replaceCharacters(value.trim().toUpperCase())
        const inputLength = inputValue.length
        return inputLength <= 1 ? [] : itemNames.filter(item => 
            this.props.replaceCharacters(item.text.toUpperCase()).indexOf(inputValue) !== -1
        );
    }
    getSuggestionValue = suggestion => suggestion.text
    renderSuggestion = suggestion => (
        <div>
          {this.props.getHighlightedText(suggestion.text, this.state.value)}
        </div>
      )
    onChange = (event, { newValue }) => {
        this.setState({
          value: newValue
        });
    };
    // Autosuggest will call this function every time you need to update suggestions.
    // You already implemented this logic above, so just use it.
    onSuggestionsFetchRequested = ({ value }) => {
        this.setState({
        suggestions: this.getSuggestions(value)
        });
    };
    // Autosuggest will call this function every time you need to clear suggestions.
    onSuggestionsClearRequested = () => {
        this.setState({
        suggestions: []
        });
    };
    createCategoryIdString = () => {
        let string = ""
        if(this.props.selectedCategories !== undefined) {
            for(let i=0; i<this.props.selectedCategories.length; i++) {
                string += this.props.selectedCategories[i] + "-"
            }
            string = string.substring(0, string.length - 1);
        } else string = "all"
        return string
    }
    onSubmitForm = (event) => {
        event.preventDefault();
        let categoryString = this.createCategoryIdString()
        this.props.setSearchedItems(this.state.value)
        this.setState({
            value: ""
        })
        this.props.history.replace("/order/orderitems/"+categoryString+"/"+this.state.value)
    }
    onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
        this.setState({
            value: ""
        })
        this.props.history.replace("/order/orderitems/"+suggestion.categId+"/0/"+suggestion.itemId)
    }
    render() {
        const { value, suggestions } = this.state;
        const inputProps = {
            placeholder: 'Keresés',
            value,
            onChange: this.onChange
        };
      return (
          <div className="searchBarContainer">        
        
            <form onSubmit={this.onSubmitForm}>
                <Autosuggest
                    suggestions={suggestions}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    getSuggestionValue={this.getSuggestionValue}
                    onSuggestionSelected={this.onSuggestionSelected}
                    renderSuggestion={this.renderSuggestion}
                    inputProps={inputProps}
                />
                <IconButton type="submit" aria-label="search">
                    <SearchIcon />
                </IconButton>                
            </form>
          </div>
      )
    }
}
export default withRouter(SearchBar);
  