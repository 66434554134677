/*eslint-disable eqeqeq*/ 
import React, { Component } from 'react'
import LoadAssets from '../../LoadAssets'
import CountButton from '../../CountButton'
import MainContext from '../../../screens/Webshop/MainContext'
import { toast } from 'react-toastify'
import {withRouter} from 'react-router-dom'

//import Card from '@material-ui/core/Card';
//import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
//import CardContent from '@material-ui/core/CardContent';
//import CardMedia from '@material-ui/core/CardMedia';
//import Typography from '@material-ui/core/Typography';

//import ItemModifierGroup from './ItemModifierGroup'

import {NavLink} from 'react-router-dom'

import {ReactComponent as CircleTrashIconActive} from "../../../svg/base/circleTrashIconActive.svg"
import {ReactComponent as CircleMinusIconActive} from "../../../svg/base/circleMinusIconActive.svg"
import {ReactComponent as CirclePlusIconActive} from "../../../svg/base/circlePlusIconActive.svg"
import {ReactComponent as CirclePlusIconPassive} from "../../../svg/base/circlePlusIconPassive.svg"
import {ReactComponent as PlusMinusFieldBg} from "../../../svg/base/plusMinusFieldBg.svg"
import {ReactComponent as CircleMinusIconPassive} from "../../../svg/base/circleMinusIconPassive.svg"

class ItemsItem extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      selected: this.props.count>0,
      count: this.props.count
    }
  }
  onChangeCount = (e) => {
    let selected = false
    if(e.count !== 0) {
      selected = true
    }
    this.setState({
      count: e.count,
      selected: selected
    })
    this.context.setOrderItemCount(this.props.categoryId, this.props.id, e.count)
  }
  onAddItem = () => {
    let itemStockLevel = this.context.getItemStockLevel(this.props.itemUuid)
    if(itemStockLevel > 0) {
      this.setState({
        count: 1
      })
      this.context.setOrderItemCount(this.props.categoryId, this.props.id, 1)
      if(!this.state.selected) {
        this.props.setSelectedItemDetails(this.props.categoryId, this.props.id)
        this.setState({
          selected: true
        })
      }
      this.notify()
    } else this.notifyError()
  }
  notify = () => toast.success(this.props.name+" kosárba helyezve!")
  notifyError = () => toast.error(this.props.name+" nincs készleten!")
  goToItemView = () => {
    if(this.props.itemView) this.props.history.replace("/order/orderitems/"+this.props.categoryId+"/0/"+this.props.id)
  }
  componentDidUpdate(oldProps) {
    if(oldProps.count !== this.props.count) {
      this.setState({
        count: this.props.count
      })
    }

  }  
  render() {
    if(this.context.isItemVisible(this.props.itemUuid) && !this.props.hidden) {
      let itemStockLevel = this.context.getItemStockLevel(this.props.itemUuid)
      //let orderItemTree = this.context.orderItemTree
      let endpoint = this.context.endpoint
      let itemImageUrl = LoadAssets(this.props.itemStyle.appImage, this.props.assets)
      let selected = ""
      //let item = orderItemTree[this.props.categoryId].items[this.props.id]
      let selectedLanguage = this.context.selectedLanguage
      //let dictionary = this.context.dictionary
      let currency = this.context.currencies.huf[selectedLanguage]
      if(itemImageUrl === endpoint+"/undefined") {itemImageUrl = endpoint+"/assets/img/brand/default_item.png"}
      (this.state.selected) ? selected = " selected " : selected = " "
      return (
          <div className={"itemListItemContainer"+ selected}>
            {itemStockLevel == 0 && <div className="outOfStock"><div className="message">Termék nincs készleten</div></div>}
            <div className={"itemCard hidden appear"}>
                <div className="itemInfo">
                  <div className="cardImg" onClick={this.goToItemView}>
                    <img src={itemImageUrl} alt="itemImg" title={this.props.name} />
                  </div>
                  <div className="cardContent">
                    <div className="itemName">
                      {this.props.itemView && <NavLink to={"/order/orderitems/"+this.props.categoryId+"/0/"+this.props.id}>
                        {this.props.getHighlightedText(this.props.name, this.props.searchString)}
                      </NavLink>}
                      {!this.props.itemView && this.props.getHighlightedText(this.props.name, this.props.searchString)}
                    </div>
                    <div className="itemDesc">
                      {this.props.desc}
                    </div>
                    <div className="itemPrice">
                      {this.props.price>0 && this.props.price+" "+currency+" / "+this.props.itemSizeName}
                    </div>
                  </div>
                </div>
              
              <div className="cardActions">
                <CountButton 
                  minValue='0'
                  maxValue='2'
                  count={this.state.count}
                  onChange={this.onChangeCount}
                  onAddItem={this.onAddItem} 
                  containerBgImage={<PlusMinusFieldBg />}
                  plusImage={<CirclePlusIconPassive />} 
                  plusActiveImage={<CirclePlusIconActive />} 
                  minusImage={<CircleMinusIconPassive />} 
                  minusActiveImage={<CircleMinusIconActive />} 
                  oneLeftImage={<CircleMinusIconPassive />}
                  oneLeftActiveImage={<CircleTrashIconActive />}
                />                
              </div>
              <div className="message">
                {this.state.count > 0 && <span className="hidden quickAppear">{this.state.count+" "+this.props.itemSizeName+" a kosárban."}</span>}
              </div>
            </div>
          </div>
      )
    } else return null;
  }
}
ItemsItem.contextType = MainContext;
export default withRouter(ItemsItem);