import React, { Component } from 'react';
import MinisiteContext from '../MinisiteContext'

class MenuItem extends Component {
    clickOnMenuItem = () => {
        this.context.handlePageChange(this.props.itemID)
    }
    render() {
        let selected = ''
        if(this.props.itemID === this.context.currentPage) selected = ' selected'
        return(
            <div className={"menuItemContainer"+selected}>
                <div className="menuItem" onClick={this.clickOnMenuItem}>
                    <div className="menuLink">{this.props.name}</div>
                </div>
            </div>
        );
    }
}
MenuItem.contextType = MinisiteContext;
export default MenuItem;