import React, { Component } from 'react'
//import MenuButton from '../../../components/MenuButton'
import MainContext from '../../Webshop/MainContext'
import { withRouter } from 'react-router-dom'
import Agreement from './Agreement'
import parse from 'html-react-parser'
import axios from 'axios'
import { FadeLoader } from 'react-spinners'
import { css } from '@emotion/core'

const override = css`
    margin: auto;
`;

class StartPayment extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      text: "",
      button: "",
      paymentInProgress: false
    }
  }
  getSimplePayButton = async () => {
    //let products = this.getProducts()
    //console.log(products)
    // let data = {"data" : {
    //   "buttonText": "Fizetés indítása",
    //   "fields": {
    //   "ORDER_REF": OrderedUUID.generate(),
    //   "ORDER_DATE": moment().format("YYYY-MM-DD"),
    //   "LANGUAGE": "HU",
    //   "BILL_FNAME": "Tester",
    //   "BILL_LNAME": "SimplePay",
    //   "BILL_EMAIL": "adamlip@gmail.com",
    //   "BILL_PHONE": "36201234567",
    //   "BILL_COMPANY": "Company name",
    //   "BILL_FISCALCODE": " ",
    //   "BILL_COUNTRYCODE": "HU",
    //   "BILL_STATE": "State",
    //   "BILL_CITY": "City",
    //   "BILL_ADDRESS": "First line address",
    //   "BILL_ADDRESS2": "Second line address",
    //   "BILL_ZIPCODE": "1234",
    //   "DELIVERY_FNAME": "Tester",
    //   "DELIVERY_LNAME": "SimplePay",
    //   "DELIVERY_EMAIL": "",
    //   "DELIVERY_PHONE": "36201234567",
    //   "DELIVERY_COUNTRYCODE": "HU",
    //   "DELIVERY_STATE": "State",
    //   "DELIVERY_CITY": "City",
    //   "DELIVERY_ADDRESS": "First line address",
    //   "DELIVERY_ADDRESS2": "Second line address",
    //   "DELIVERY_ZIPCODE": "1234" 
    //   },
    //   "products" : products
    // }}
    //console.log(data)
    //let dictionary = this.context.dictionary
    //let selectedLanguage = this.context.selectedLanguage
    
      //let startPayment = dictionary['app_order_startPayment'][selectedLanguage]
      //let paymentInProgressText = dictionary['app_order_paymentInProgress'][selectedLanguage]
    if(localStorage['lastOrderUUID'] !== undefined) {
        let data = {
          "orderUUID": localStorage['lastOrderUUID'],
          "buttonText": "Fizetés indítása",
          "languageCode": "hu"
        }
        const endpoint = this.context.endpoint
        let response = await axios(endpoint+'/api/v1/payment/spf', {
          method: "post",
          data: JSON.stringify(data)
        })
        let txt = response.data.data
        //console.log(response.data.success)
        // let txt = await response.text()
        if(response.data.success) return txt
        else if(!response.data.success) {
          this.setState({
            paymentInProgress: true
          })
          return("<p>Fizetés folyamatban.</p>")
        }
    }
  }
  componentDidMount() {
    if(this.props.match.params.orderuuid) localStorage['lastOrderUUID'] = this.props.match.params.orderuuid
    this.setTextAndButton()
    this.context.setSearchVisible(false)
  }
  getAgreementText = async () => {

    let response = await fetch(this.context.endpoint+'/assets/docs/brand/agreement.txt')
    let txt = await response.text()
    this.setState({
      text: txt
    })
  } 
  setTextAndButton = async () => {
    this.getAgreementText()
    let button = await this.getSimplePayButton()
    if(button !== -1) {
      localStorage.setItem('simplePayButton', button)
      this.setState({
        button: button
      })
    }    
  }
  render() {
    let endpoint = this.context.endpoint
    return (
      <React.Fragment>
        <div className="orderStartPayment">
            <Agreement text={this.state.text} />
            <a href="https://simplepartner.hu/PaymentService/Fizetesi_tajekoztato.pdf" target="_blank" rel="noopener noreferrer">
              <img 
                  className="simpleLogos" 
                  src={endpoint+'/assets/img/base/simplePay.jpg'} 
                  alt="simpleLogos" 
              />
            </a>
            {this.state.button === "" && <FadeLoader css={override} color={this.context.loaderColor} />}
            {this.state.button !== "" && this.state.button !== undefined && <div className="simplePayButton hidden appear">{parse(this.state.button)}</div>}
        </div>
      </React.Fragment>
    );
  }
}
StartPayment.contextType = MainContext;
export default withRouter(StartPayment);
  