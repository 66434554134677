import React, { Component } from 'react';

class Footer extends Component {

    render() {
        return(
            <div className="footer">
                <div className="left">@Minden jog fenntartva • 2019</div>
                <div className="center">STÉG • 8237</div>
                <div className="right">ÁSZF</div>
            </div>
        );
    }
}

export default Footer;