import React, {Component} from 'react'
import './style.css'
//import {BrowserRouter, Route, Switch} from 'react-router-dom'
import Menu from './Menu'
import Footer from './Footer'
import ReactPageScroller from "react-page-scroller"
import MinisiteContext from './MinisiteContext'
import axios from "axios"
//import data from './minisiteData.json'
import Page from './Page'
import config from '../../config'

class Minisite extends Component  {
  constructor(props, context) {
    super(props, context);
      this.state = {
        currentPage: 0,
        handlePageChange: this.handlePageChange,
        pages: null,
        assets: null,
        endpoint: config.endpoint
      };
  }
  handlePageChange = number => {
    this.setState({ currentPage: number }); // set currentPage number, to reset it from the previous selected.
  };
  getPages = async () => {
    try {
      let response = await axios(this.state.endpoint+'/api/v1/pages/all', {      
        method: 'get',
      })
      let data = response.data
      localStorage['ms_pages'] = JSON.stringify(data)
      return data
    } catch(e) {
      console.log(e)
      try {
        let data = JSON.parse(localStorage['ms_pages'])
        return data
      } catch(e) {
        return []
      }
    }
  }
  getCatalog = async () => {
    try {
      let response = await axios(this.state.endpoint+'/api/v1/order/getCatalog', {      
        method: 'post',
        body: {
          locationUUID: "",
          dataNeeded: {
            assets: true
          }
        }
      })
      let data = response.data.assets
      localStorage['ms_catalog'] = JSON.stringify(data)
      return data
    } catch(e) {
      console.log(e)
      try {
        console.log("Get catalog from storage")
        let data = JSON.parse(localStorage['ms_catalog'])
        return data
      } catch(e) {
        return []
      }
    }
  }
  componentDidMount = async () => {
    let pages = await this.getPages()
    let assets = await this.getCatalog()
    this.setState({ pages, assets })
  }
  render() {
    if(this.state.pages == null) return null
    return (
      <MinisiteContext.Provider value = {this.state}>
        <div className="mainContainer">
          <Menu content={this.state.pages} />          
          <ReactPageScroller
            pageOnChange={this.handlePageChange}
            containerWidth="100%" 
            containerHeight="100%"
            customPageNumber={this.state.currentPage}
          >
            {this.state.pages.map((page, index) => <Page key={index} backgroundImage={page.backgroundImage} pageType={page.pageType} content={page.content} />)}
          </ReactPageScroller>          
          <Footer />
        </div>
      </MinisiteContext.Provider>
    );
  }
}

export default Minisite;
