import React, { Component } from 'react'
import parse from 'html-react-parser'

class Failed extends Component {
    renderStatus = () => {
      switch(this.props.status) {
        case "FAIL": return(<p>Kérjük, ellenőrizd a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adtál meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedj kapcsolatba lépni kártyakibocsátó bankoddal.</p>)
        //break
        case "FAILED": return(<p>Kérjük, ellenőrizd a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adtál meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedj kapcsolatba lépni kártyakibocsátó bankoddal.</p>)
        //break
        case "CANCEL": return(<p>Megszakítottad a fizetést.</p>)
        //break
        case "CANCELLED": return(<p>Megszakítottad a fizetést.</p>)
        //break
        case "TIMEOUT": return(<p>Túllépted a tranzakció elindításának lehetséges maximális idejét.</p>)
        //break
        case "CARD_NOTAUTHORIZED": return(<p>Kérjük, ellenőrizd a tranzakció során megadott adatok helyességét. Amennyiben minden adatot helyesen adtál meg, a visszautasítás okának kivizsgálása érdekében kérjük, szíveskedj kapcsolatba lépni kártyakibocsátó bankoddal.</p>)
        //break
        default: return(<p>Egyéb hiba: {this.props.status}</p>)
        //break
      }
    }
    render() {
     
        return (
           <div className="failedPaymentContainer">
             <h3>Sikertelen tranzakció.</h3>
             {this.props.transactionID && <p>SimplePay tranzakció azonosító: {this.props.transactionID}</p>}
              {this.renderStatus()}
             <p>Ha újra szeretnél próbálkozni, indítsd el mégegyszer.</p>
             <div className="simplePayButton hidden appear">{parse(localStorage['simplePayButton'])}</div>
           </div>
        )
  }
}
export default Failed
  