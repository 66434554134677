import React, {Component} from 'react'
import {Route, Switch, withRouter} from 'react-router-dom'
import MainContext from '../Webshop/MainContext'
//import OrderStart from './OrderStart'
import OrderItems from './OrderItems'
import BasketView from './BasketView'
import UserData from './UserData'
import StartPayment from './StartPayment'
import OrderStart from './OrderStart'
import Backref from './Backref'
import Login from './UserData/Login'

class Order extends Component {
  render() {
    if(this.context.guestShopping || this.context.loggedIn) {
      return (
        <Switch>
            {/*<Route path={"/"} component={OrderItems} />*/}
            <Route path={"/order/orderitems/:categId/:search/:itemId"} component={OrderItems}/>
            <Route path={"/order/orderitems/:categId/:search"} component={OrderItems}/>
            <Route path={"/order/orderitems/:categId"} component={OrderItems}/>
            <Route path={"/order/orderitems"} component={OrderItems} />
            <Route path={"/order/basket"} component={BasketView} />
            <Route path={"/order/userdata"} component={UserData} />
            <Route path={"/order/startpayment/:orderuuid"} component={StartPayment} />
            <Route path={"/order/startpayment"} component={StartPayment} />
            <Route path={"/order/backref"} component={Backref} />
            <Route path={"/order"} component={OrderItems} />
            <Route path={"/"} component={OrderStart} />
            
        </Switch>
      )
    } else {
      return(
        <Login 
            userLoggedIn={this.context.loggedIn} 
            validateUserPin={this.context.validateUserPin}
            generateUserPin={this.context.generateUserPin}
            userLoginWithCredentials={this.context.userLoginWithCredentials}
            shopAsGuest={this.context.guestShopping}
        />
      )
    }
  }
}

Order.contextType = MainContext;
export default withRouter(Order);
