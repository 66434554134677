import React, { Component } from 'react';

class PageType1 extends Component {

    render() {
        return(
        <React.Fragment>
            <div style={{display: "flex", height: "100%"}}>
                <img src={this.props.content.img.src} alt="logoImg" height={this.props.content.img.height} style={{margin: "auto"}}/>
            </div>
        </React.Fragment> )
    }
}
export default PageType1;