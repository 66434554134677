import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import Login from './Login'
import Details from './Details'
import MainContext from '../../Webshop/MainContext'
//import AskUserForData from './AskUserForData'
import Checkout from './Checkout'

class UserData extends Component {
  constructor(props, context) {
      super(props, context);
      this.state = {
        loginFormReady: false,
        skipUserDataForm: true
      }
  }
  setLoginFormReady = (e) => {
      this.setState({
        loginFormReady: e
      })
  }
  onSkip = () => {
      this.setState({
          skipUserDataForm: true
      })
  }
  componentDidMount() {
      this.context.setSearchVisible(false)
  }
  render() {
    let userDataComplete = this.context.checkUserMinimalRequiredData()
    let showLoginForm = !this.state.loginFormReady
    let showDetailsForm = !this.context.showCheckout && (this.state.loginFormReady || this.context.loggedIn) && (!this.context.loggedIn || userDataComplete || this.state.skipUserDataForm)
    //let showAskForDataForm = this.context.loggedIn && !userDataComplete && !this.state.skipUserDataForm
    let showCheckout = this.context.showCheckout
    if(showLoginForm) {
      //console.log("1")
      return(
        <div className="userDataContainer">
          <Login 
            userLoggedIn={this.context.loggedIn} 
            validateUserPin={this.context.validateUserPin}
            generateUserPin={this.context.generateUserPin}
            setLoginFormReady={this.setLoginFormReady}
            userLoginWithCredentials={this.context.userLoginWithCredentials}
            shopAsGuest={this.context.guestShopping}
          />
        </div>
      )
    }
    else if(showDetailsForm) {
      //console.log("2")
      return (
        <div className="userDataContainer">
          <Details 
            dictionary={this.context.dictionary} 
            selectedLanguage={this.context.selectedLanguage}
            deliveryType={this.context.deliveryType}
            setDeliveryType={this.context.setDeliveryType}
            //setDeliveryInfo={this.context.setDeliveryInfo}  
            setCheckoutVisible={this.context.setCheckoutVisible}
            paymentType={this.context.paymentType}  
            setPaymentType={this.context.setPaymentType}
            //setInvoiceInfo={this.context.setInvoiceInfo}
            endpoint={this.context.endpoint}
            userData={this.context.userData}
            setUserInfo={this.context.setUserInfo}
            deliveryConfig={this.context.deliveryConfig}
            paymentConfig={this.context.paymentConfig}
          />
        </div>
      )
    }
    /*else if(showAskForDataForm) {
      //console.log("3")
      return(
        <div className="userDataContainer">
          <AskUserForData 
            onSkip={this.onSkip}
            dictionary={this.context.dictionary}
            selectedLanguage={this.context.selectedLanguage}
            endpoint={this.context.endpoint}
            loginWithToken={this.context.loginWithToken}
          />
        </div>
      )
    }*/
    else if(showCheckout) {
      //console.log("4")
      return( <Checkout /> )
    }
    else {
      //console.log("5")
      return null
    }
  }
}
UserData.contextType = MainContext;
export default withRouter(UserData);