import brandConfig from './brandConfig.json';

const brandID = process.env.REACT_APP_BRAND_ID
//const endpoint = process.env.NODE_ENV === "development" ? "https://dev.okeoke.io/"+brandID : "https://app.okeoke.io/"+brandID
//console.log(process.env)
const endpoint = "https://app.okeoke.io/"+brandID
//const endpoint = "http://10.10.10.202:9011"
const config = {
    brandID: brandID,
    endpoint: endpoint,
    basename: brandConfig.basename === undefined ? "/"+brandID+"/webshop" : brandConfig.basename,
    search: brandConfig.search === undefined ? false : brandConfig.search,
    welcomePage: brandConfig.welcomePage === undefined ? false : brandConfig.welcomePage,
    deliveryTypes: brandConfig.deliveryTypes === undefined ? false : brandConfig.deliveryTypes,
    paymentTypes: brandConfig.paymentTypes === undefined ? false : brandConfig.paymentTypes,
    itemView: brandConfig.itemView === undefined ? false : brandConfig.itemView,
    stockControl: brandConfig.stockControl === undefined ? false : brandConfig.stockControl,
    loaderColor: brandConfig.loaderColor === undefined ? "grey" : brandConfig.loaderColor,
    guestShopping: brandConfig.guestShopping === undefined ? false : brandConfig.guestShopping,
    backgroundImg: brandConfig.backgroundImg === undefined ? false : brandConfig.backgroundImg,
    minisiteURL: brandConfig.minisiteURL === undefined ? "" : brandConfig.minisiteURL,
    webshopURL: brandConfig.webshopURL === undefined ? "" : brandConfig.webshopURL
}

export default config;
