import React, { Component } from 'react';
import ItemsItem from './ItemsItem';
import MainContext from '../../../screens/Webshop/MainContext'
import SelectedItem from '../SelectedItem'
import {withRouter} from 'react-router-dom'

class Items extends Component {
  items = [];
  nextItemIndex = 0;
  
  getNextItemIndex = () => {
    let orderItemTree = this.context.orderItemTree
    let items = orderItemTree[this.props.categoryId].items;
    for(let i=this.nextItemIndex; i<items.length; i++) {
      if(this.context.isItemVisible(items[i].uuid) && !items[i].hidden) {
        this.nextItemIndex = i+1;
        return i;
      }
    }
    return -1;
  }

  renderNextItem = () => {
    let index = this.getNextItemIndex();
    let assets = this.context.assets
    let selectedLanguage = this.context.selectedLanguage
    if(index > -1) {
      let name = "MISSING_NAME"
      let desc = "MISSING_DESC"
      let price = 0
      let count = 0
      let sizeName = "MISSING_SIZE_NAME"
      let itemStyle = {}
      try {
        name = JSON.parse(this.items[index].name)[selectedLanguage];
        desc = JSON.parse(this.items[index].description)[selectedLanguage];
        price = parseInt(this.items[index].price)
        sizeName = JSON.parse(this.context.getItemSizeName(this.context.itemSizes, this.items[index].default_size))[selectedLanguage]
        itemStyle = JSON.parse(this.items[index].attributes)
      } catch(e) {
        console.log(e)
      }
      //let sizeName = JSON.parse(this.items[index].selectedItemSize)[selectedLanguage];     
      if (this.items[index].count) count = this.items[index].count
      return <ItemsItem
              name={name}
              desc={desc}
              price={price}
              key={this.items[index].uuid}
              itemUuid={this.items[index].uuid} 
              id={index}
              hidden={this.items[index].hidden}
              count={count} 
              setSelectedItemDetails={this.context.setSelectedItemDetails}
              itemStyle={itemStyle}
              assets={assets}
              itemBgImage=""
              delay={index}
              categoryId={this.props.categoryId}
              itemSizeName={sizeName}
              searchString={this.props.searchString}
              getHighlightedText={this.context.getHighlightedText}
              itemView={this.context.itemView}
            />;
    } else return null
  }

  renderItems = () => {
    let orderItemTree = this.context.orderItemTree
    
    if(orderItemTree.length>0) {
      this.items = orderItemTree[this.props.categoryId].items    
      const listItems = this.items.map((item, index) => (index>=this.nextItemIndex && this.renderNextItem()));
      this.nextItemIndex = 0;
      if(!listItems.every(element => element === null)) {
        return (
            <div className="categoryContainer">   
              <div className="title">{JSON.parse(this.context.orderItemTree[this.props.categoryId].name)[this.context.selectedLanguage]}</div>
              <div className="itemsContainer" id={"itemsContainerOfCateg"+this.props.categoryId} >  
                {listItems}
              </div>
            </div>
        );
      } else return null
    } else return 0
  };
  render() {
    if(this.props.match.params.itemId === undefined) {
      return (this.renderItems())   
    } else {
      return(
        <SelectedItem 
          selectedItemId={parseInt(this.props.match.params.itemId)} 
          categoryId={this.props.categoryId}
        />
      )
    }
  }
}
Items.contextType = MainContext;
export default withRouter(Items);
  