import React, { Component } from "react"
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { toast } from "react-toastify";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: "",
      password2: ""
    };
  }
  handlePassChange = (event) => {
    this.setState({ [event.target.name]: event.target.value })
  }
  onSubmit = async (event) => {
    event.preventDefault()
    if(this.state.password === this.state.password2) {
      if(this.state.password.length > 5) {
        let response = await this.props.setUserPass(this.state.password)
        if(response) {
          this.notifySuccess()
        } else {
          this.notifyError()
        }
      } else this.notifyPassErrorShort()
    } else this.notifyPassError()
  }
  notifySuccess = () => toast.success("Jelszó sikeresen mentve!")
  notifyError = () => toast.error("Hiba történt!")
  notifyPassError = () => toast.error("Jelszavak nem egyeznek!")
  notifyPassErrorShort = () => toast.error("Jelszó legalább 6 karakter legyen!")
  render() {
    return (
      <div className="changePassContainer">
        <form onSubmit={this.onSubmit}>
          <TextField
            id="password"
            name="password"
            label={"Jelszó"}
            value={this.state.password}
            onChange={this.handlePassChange}
            margin="normal"
            type="password"
            variant="filled"
            required
          />
          <TextField
            id="password2"
            name="password2"
            label={"Jelszó újra"}
            value={this.state.password2}
            onChange={this.handlePassChange}
            margin="normal"
            type="password"
            variant="filled"
            required
          />
          <Button className="submitPass" variant="contained" color="primary" type="submit">
              Jelszó mentése
          </Button>
        </form>
      </div>
    );
  }
}
export default ChangePassword;
