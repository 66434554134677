import React, { Component } from 'react'
import {ReactComponent as BackArrow} from "../../svg/base/backArrow.svg"
import Cart from '@material-ui/icons/ShoppingCart'

class CountButton extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            count: props.count ? +props.count : 0,
            minValue: props.minValue ? +props.minValue : 0,
            maxValue: props.maxValue ? +props.maxValue : 10,
            preValue: props.count ? +props.count : 0
        };
    }
    handleCount = (e) => {
        
        //console.log(e.target.value)
        let value = e.target.value
        if(value.match(/^[0-9]*$/) !== null) {
            value === "" ? value = 1 : value = parseInt(value) 
            if(parseInt(value) > this.props.maxValue) value = this.props.maxValue
            this.setState({
                count: parseInt(value)
            },this.stateUpdated)
        }        
    }
    stateUpdated = () => {
        let diff = this.state.count-this.state.preValue;
        this.props.onChange({count: this.state.count, diff: diff});
    }
    canSelect = () => {
        if(typeof(this.props.canSelect) === 'function') return this.props.canSelect()
        else return 1;
    }    
    canIncrement = () => {
        return this.state.count<this.state.maxValue && this.canSelect();
    }
    incrementCount = () => {
        if(!this.canIncrement()) return;
        this.setState({
            preValue: this.state.count,
            count: this.state.count + 1
        },this.stateUpdated);
    }  
    canDecrement = () => {
        return this.state.count>this.state.minValue;
    }
    decrementCount = () => {
        if(!this.canDecrement()) return;
        this.setState({
            preValue: this.state.count,
            count: this.state.count - 1
        },this.stateUpdated);      
    }
   componentDidUpdate(prevProps) {
        if (this.props.count !== prevProps.count) {
            this.setState({
                count: this.props.count
            })
        }
    }
    render() {

        let plus;
        let minus;
        
        minus = this.canDecrement() ? (this.state.count===1 ? this.props.oneLeftActiveImage : this.props.minusActiveImage) : this.props.oneLeftImage
        plus = this.canIncrement() ? this.props.plusActiveImage : this.props.plusImage;
        if(this.state.count !== 0) {
            return (
                <div className="plusMinusField-container">
                    <input className="plusMinusField-count" type="number" value={this.state.count} onChange={this.handleCount} />
                    <div className={'plusMinusField-minus '+(this.canDecrement() ? 'active ' : '') +(this.state.count===1 ? 'oneLeft' : '')} onClick={this.decrementCount} >{minus}</div>
                    <div className={'plusMinusField-plus '+(this.canIncrement() ? 'active ' : '')} onClick={this.incrementCount} >{plus}</div>
                </div>
            );
        } else {
            return (
                <div className="toBasketIcon" onClick={this.props.onAddItem}>
                    <div className="backArrow"><BackArrow /></div>
                    <div className="cartIcon"><Cart /></div>
                </div>
            )
        }
    }
}
export default CountButton;