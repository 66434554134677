import React, { Component } from 'react'
import MainContext from '../../Webshop/MainContext'
import Basket from '../../../components/Order/Basket'
import SubmitSuccess from './SubmitSuccess'
import Button from '@material-ui/core/Button'
import { FadeLoader } from 'react-spinners'
import { css } from '@emotion/core'
import Checkbox from '@material-ui/core/Checkbox'
import { Popover } from "@material-ui/core"

//import {NavLink} from 'react-router-dom'

const override = css`
    display: block;
    margin: 0 auto;
    margin-top: 20vh;
`;

class BasketView extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        loading: false,
        orderSubmitted: false,
        deliveryInfo: localStorage['deliveryInfo'] !== undefined ? JSON.parse(localStorage['deliveryInfo']) : null,
        invoiceInfo: localStorage['invoiceInfo'] !== undefined ? JSON.parse(localStorage['invoiceInfo']) : null,
        dataPolicyChecked: false,
        termsAndCondChecked: false,
        anchorTerms: null,
        anchorPolicy: null,
        anchorPolicyMessage: null
    }
  }
    handleCheckbox = (e) => {
        this.setState({
            [e.target.name] : !this.state[e.target.name]
        })
    }
    handleClickTerms = event => {
        this.setState({
            anchorTerms: event.currentTarget
        })
    }
    handleCloseTerms = () => {
        this.setState({
            anchorTerms: null
        });
    }
    handleClickPolicy = event => {
        this.setState({
            anchorPolicy: event.currentTarget
        })
    }
    handleClosePolicy = () => {
        this.setState({
            anchorPolicy: null
        });
    }
    handleClosePolicyMessage = () => {
        this.setState({
            anchorPolicyMessage: null
        });
    }
  submitOrder = async(e) => {
    if((this.state.termsAndCondChecked && this.state.dataPolicyChecked)) {
        let lastOrderUUID = localStorage['orderUUID']
        let response = await this.context.submitOrder()    
        if(response !== 0 && response.success) {
            this.setState({
                loading: true
            })
            localStorage['lastOrderUUID'] = lastOrderUUID
            localStorage['lastOrderNumber'] = response.orderNumber
            this.context.resetOrderItemtree()
            setTimeout(this.submitSuccess, 1000)
        }
    } else {
        this.setState({
            anchorPolicyMessage: e.currentTarget
        })
    }
  }
  submitSuccess = () => {
      this.setState({
          loading: false,
          orderSubmitted: true
      })
  }
  editUserData = () => {
      this.context.setCheckoutVisible(false)
  }
  componentDidMount() {
    this.context.setSearchVisible(false)
}
  render() {
        if(!this.state.loading && !this.state.orderSubmitted) {
            let invoiceAddress = this.state.invoiceInfo != null && this.state.invoiceInfo.address !== "" ? JSON.parse(this.state.invoiceInfo.address) : null
            let deliveryAddress = this.state.deliveryInfo != null && this.state.deliveryInfo.address !== ""  ? JSON.parse(this.state.deliveryInfo.address) : null
            if(invoiceAddress !== null && deliveryAddress !== null) {
                return (
                    <div className="checkoutContainer">
                        <div className="userDataContainer">
                            <div className="contact">
                                <div className="title">Email cím a rendelés visszaigazolásához:</div>
                                <div className="name">{localStorage['userEmail']}</div>
                            </div>
                            {localStorage['invoiceNeeded'] === "true" && this.state.invoiceInfo !== null && <div className="invoiceInfo">
                                <div className="title">Számlázási adatok:</div>
                                <div className="name">{this.state.invoiceInfo.name}</div>
                                <div className="address">{invoiceAddress.zip+", "+invoiceAddress.city+", "+invoiceAddress.street+" "+invoiceAddress.streetNumber+" "+invoiceAddress.floor}</div>
                                {this.state.invoiceInfo.tax !== "" && <div className="name">Adószám: {this.state.invoiceInfo.tax}</div>}
                            </div>}
                            {this.state.deliveryInfo !== null && <div className="deliveryInfo">
                                {this.state.deliveryInfo.name !== "" && 
                                    <div className="name">
                                        <div className="title">Megrendelő neve: </div>
                                        <div className="text">{this.state.deliveryInfo.name}</div>
                                    </div>
                                }
                                {this.state.deliveryInfo.telNumber !== "" && 
                                    <div className="phone">
                                        <div className="title">Megrendelő telefonszáma: </div>
                                        <div className="text">{this.state.deliveryInfo.telNumber}</div>
                                    </div>
                                }
                                {this.context.deliveryType === "delivery" && <div className="deliveryData">
                                    <div className="title">Szállítási cím: </div>
                                    <div className="address">{deliveryAddress.zip+", "+deliveryAddress.city+", "+deliveryAddress.street+" "+deliveryAddress.streetNumber+" "+deliveryAddress.floor}</div>
                                    {this.state.deliveryInfo.notes !== "" && <div className="notes">{this.state.deliveryInfo.notes}</div>}
                                </div>}
                                {this.context.deliveryType === "store" && <div className="deliveryData">Személyes átvétel.</div>}
                            </div>}
                        </div>
                        <div className="basketContainer">
                            <Basket 
                                orderItemTree={this.context.orderItemTree} 
                                isItemVisible={this.context.isItemVisible}
                                selectedLanguage={this.context.selectedLanguage}
                                dictionary={this.context.dictionary}
                                assets={this.context.assets}
                                endpoint={this.context.endpoint}
                                currencies={this.context.currencies}
                                resetOrderItemtree={this.context.resetOrderItemtree}
                                setOrderItemCount={this.context.setOrderItemCount}
                                addClass="checkout"
                                setItemsToSubmit={this.context.setItemsToSubmit}
                                getDeliveryCost={this.context.getDeliveryCost}
                                setDeliveryCost={this.context.setDeliveryCost}
                                deliveryType={this.context.deliveryType}
                                itemView={this.context.itemView}
                            />
                            <div className="actions">
                                <div className="policy">
                                    <div className="block">
                                        <div className="label dataPolicyLabel" onClick={this.handleClickPolicy}>
                                          <p>Adatkezelési nyilatkozatot elolvastam</p>
                                        </div>
                                        <Checkbox checked={this.state.dataPolicyChecked} onChange={this.handleCheckbox} name="dataPolicyChecked" />
                                        <Popover
                                            id={"policy-popover"}
                                            open={this.state.anchorPolicy !== null}
                                            anchorEl={this.state.anchorPolicy}
                                            onClose={this.handleClosePolicy}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center"
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center"
                                            }}
                                            >
                                            <div style={{padding: "10px"}}><a href={this.context.endpoint+'/assets/docs/brand/policy.pdf'} target="_blank" rel="noopener noreferrer">Adatkezelési nyilatkozat letöltése</a></div>
                                        </Popover>
                                    </div>
                                    <div className="block">
                                        <div className="label termsLabel" onClick={this.handleClickTerms}>
                                            <p>Általános Szerződési Feltételeket elolvastam</p>
                                        </div>
                                   
                                        <Checkbox checked={this.state.termsAndCondChecked} onChange={this.handleCheckbox} name="termsAndCondChecked" />
                                        <Popover
                                            id={"terms-popover"}
                                            open={this.state.anchorTerms !== null}
                                            anchorEl={this.state.anchorTerms}
                                            onClose={this.handleCloseTerms}
                                            anchorOrigin={{
                                                vertical: "bottom",
                                                horizontal: "center"
                                            }}
                                            transformOrigin={{
                                                vertical: "top",
                                                horizontal: "center"
                                            }}
                                            >
                                            <div style={{padding: "10px"}}><a href={this.context.endpoint+'/assets/docs/brand/terms.pdf'} target="_blank" rel="noopener noreferrer">Általános szerződési feltételek letöltése</a></div>
                                        </Popover>  
                                    </div>                              
                                </div>
                                <div className="buttons">
                                    <Button className="editData" variant="contained" color="secondary" onClick={this.editUserData}>
                                        Adataim szerkesztése
                                    </Button>
                                    {this.context.itemsToSubmit !== null && this.context.itemsToSubmit.length > 0 && 
                                    <Button className="submitOrder" variant="contained" color="primary" onClick={this.submitOrder} >
                                        Rendelés leadása
                                    </Button>} 
                                    <Popover
                                        id={"policy-message-popover"}
                                        open={this.state.anchorPolicyMessage !== null}
                                        anchorEl={this.state.anchorPolicyMessage}
                                        onClose={this.handleClosePolicyMessage}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                        >
                                        <div style={{padding: "10px", color: "red"}}>A továbblépéshez fogadd el az adatkezelési nyilatkotatot és az általános szerződési feltételeket</div>
                                    </Popover> 
                                </div>  
                            </div>                    
                        </div>
                    </div>
                )
            } else return (
                <div className="error">
                    <div className="message">Hibás adatok!</div>
                    <Button className="editData" variant="contained" color="secondary" onClick={this.editUserData}>
                        Adataim szerkesztése
                    </Button>
                </div>
            )
        } else {
            return(
                <div className="submitedOrderContainer">
                    {this.state.loading && <FadeLoader css={override} color={this.context.loaderColor} loading={this.state.loading}/>}
                    {!this.state.loading && this.state.orderSubmitted && <SubmitSuccess paymentType={this.context.paymentType} />}
                </div>
            )
        }
    }
  }
  BasketView.contextType = MainContext;
  export default BasketView;
  