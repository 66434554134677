import React, { Component } from 'react'
//import Categories from '../../../components/Order/Categories'
import Items from '../../../components/Order/Items'
import MainContext from '../../Webshop/MainContext'
import {withRouter} from 'react-router-dom'

class OrderItems extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
    }
  }
  renderItem = (index) => {
    let searchString = ""
    if(this.props.match.params.search !== undefined) searchString = this.props.match.params.search
    return <Items key={index} categoryId={index} searchString={searchString}/>;
  }
  renderItems = () => {
    let selectedCategories = ""
    if(this.props.match.params.categId !== undefined) selectedCategories = this.props.match.params.categId.split("-").map(Number)
    let orderItemTree = this.context.orderItemTree
    if(orderItemTree !== null) {
      const listItems = orderItemTree.map((item, index) => {
        if(selectedCategories === "" || selectedCategories.includes(NaN) || selectedCategories.includes(index)) {
         return this.renderItem(index, '')
        } else return null
      })
      return <React.Fragment>{listItems}</React.Fragment>;
    }
  }
  setSearchedItems = () => {
    let searchString = ""
    if(this.props.match.params.search !== undefined) searchString = this.props.match.params.search
    this.context.setSearchedItems(searchString)
    //console.log(count)
  }
  componentDidMount() {
    this.context.setSearchVisible(this.context.search)
    this.setSearchedItems()
  }
  render() {    
    if(this.context.orderItemTree !== null) {
      let searchString = ""
      if(this.props.match.params.search !== undefined) searchString = this.props.match.params.search
      return (
        <React.Fragment>
            <div className="orderItemsContainer">
                <div className="title">
                  {this.props.match.params.itemId === undefined && searchString !== "" && <h1>{"Találatok erre \""+searchString+"\""}</h1>}
                </div>
                <div id="itemsByCategory">
                    {this.renderItems()}
                </div>
            </div>
        </React.Fragment>
      )
    } else return null
  }
}
OrderItems.contextType = MainContext;
export default withRouter(OrderItems);
  