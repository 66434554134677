// - Order/OrderWhere.js

import React, { Component } from 'react'
import parse from 'html-react-parser';
//import LoadAssets from '../../../components/LoadAssets'

class Agreement extends Component {

    render() {
        const style = {
            fontFamily: "sans-serif",
            fontSize: "0.55em",
            marginBottom: "3vh"
        }
        return (
            
            <div className="orderAgreement hidden appear">
                <p style={style}>{parse(this.props.text)}</p>
                <p style={style}>Fizetést elindítva egyben elfogadja a nyilatkozatot. </p>
            </div>

        );
    }
}

export default Agreement;
  