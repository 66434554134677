import React, { Component } from 'react'
import MainContext from '../../Webshop/MainContext'
import Basket from '../../../components/Order/Basket'
import {NavLink} from 'react-router-dom'
import Button from '@material-ui/core/Button'

class BasketView extends Component {
    componentDidMount() {
        this.context.setSearchVisible(false)
    }
    render() {
        return (
            <div className="basketContainer">
                <Basket 
                    orderItemTree={this.context.orderItemTree} 
                    isItemVisible={this.context.isItemVisible}
                    selectedLanguage={this.context.selectedLanguage}
                    dictionary={this.context.dictionary}
                    assets={this.context.assets}
                    endpoint={this.context.endpoint}
                    currencies={this.context.currencies}
                    resetOrderItemtree={this.context.resetOrderItemtree}
                    setOrderItemCount={this.context.setOrderItemCount}
                    addClass=""
                    setItemsToSubmit={this.context.setItemsToSubmit}
                    getDeliveryCost={this.context.getDeliveryCost}
                    itemView={this.context.itemView}
                />
                {this.context.itemsToSubmit !== null && this.context.itemsToSubmit.length > 0 && 
                <NavLink to="/order/userdata">
                    <Button className="submitOrder" variant="contained" color="primary">
                        Tovább az adataim megadásához
                    </Button>
                </NavLink>}
            </div>
        )
    }
}
BasketView.contextType = MainContext;
export default BasketView;
  