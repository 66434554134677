import React, { Component } from 'react'
import MainContext from '../../Webshop/MainContext'
import Categories from '../../../components/Order/Categories'
import OrderItems from '../OrderItems'
import Login from '../UserData/Login'

class OrderStart extends Component {
    componentDidMount() {
        this.context.setSearchVisible(this.props.search)
    }
    render() {
        if(this.context.welcomePage) {
            return (
                <div className="orderStart">
                    <div className="startContainer" style={{
                        backgroundImage: 'url('+this.context.endpoint+'/assets/img/brand/background2.jpg)'
                        }}>
                            <Categories 
                                orderItemTree={this.context.orderItemTree}
                                selectedLanguage={this.context.selectedLanguage}
                                assets={this.context.assets}
                                endpoint={this.context.endpoint}
                                setSearchedItems={this.context.setSearchedItems}
                                searchBar={false}
                            />
                            <div className="startContent">
                                <div className="welcome">Üdvözlünk!</div>
                                <div className="message">A vásárlás megkezdéséhez válassz kategóriát vagy használd a keresést!</div>
                            </div>
                    </div>
                </div>
            )
        } else {
            if(this.context.guestShopping || this.context.loggedIn) {
                return(<OrderItems />)
            } else return(
                <Login 
                    userLoggedIn={this.context.loggedIn} 
                    validateUserPin={this.context.validateUserPin}
                    generateUserPin={this.context.generateUserPin}
                    userLoginWithCredentials={this.context.userLoginWithCredentials}
                    shopAsGuest={this.context.guestShopping}
                />
            )
        }
    }
}
OrderStart.contextType = MainContext
export default OrderStart