import React, { Component } from "react"
import { TextField } from "@material-ui/core"
import axios from 'axios'
//import * as Yup from "yup";

class Address extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
        zip: "",
        state: "",
        city: "",
        floor: "",
        street: "",
        streetNumber: "",
        zipHelperText: "",
        stateHelperText: "",
        cityHelperText: "",
        streetHelperText: "",
        streetNumberHelperText: ""
    };
  }
  getRegionByZip = async (zip) => {
    let response = await axios(this.props.endpoint+'/api/v1/address/regionByZip/'+zip)
    return(response.data)
  }
  setAddressByZip = async (zip) => {
    if(zip.length < 5) {
        if(zip.length === 4) {
            let addressInfo = await this.getRegionByZip(zip)
            await this.setState({
              zip: zip,
              state: addressInfo.state !== undefined ? addressInfo.state : "",
              city: addressInfo.region !== undefined ? addressInfo.region + (addressInfo.sub_region !== "" ? ", " + addressInfo.sub_region : "") : "",
              zipHelperText: (addressInfo.state === undefined || addressInfo.region === undefined) ? "Hibás irányítószám" : ""
            });
            this.props.onChange(({target: {name: this.props.name, value: JSON.stringify(this.state)}}))
            
        } else {
            await this.setState({ 
              zip: zip,
              state: "",
              city: ""
            })
        }
    }        
  }
  setValue = async (name, value) => {
    await this.setState({ [name]: value }) 
    this.props.onChange(({target: {name: this.props.name, value: JSON.stringify(this.state)}}))
  }
  setHelperText = (name, value) => {
    this.setState({
      [name]: value
    })
  }
  onChangeValue = async (e) => {
    if(e.target !== undefined) {
      let name = e.target.name
      let value = e.target.value
      switch(name) {
        case "zip": this.setAddressByZip(value)
          break
        case "state": 
          if(value === "") { this.setHelperText("stateHelperText", "megye megadása kötelező") }
          if(value !== "") { this.setHelperText("stateHelperText", "") }
          this.setValue(name, value)
          break
        case "city": 
          if(value === "") { this.setHelperText("cityHelperText", "város megadása kötelező") }
          if(value !== "") { this.setHelperText("cityHelperText", "") }
          this.setValue(name, value)
          break
        case "street": 
          if(value === "") { this.setHelperText("streetHelperText", "utca megadása kötelező") }
          if(value !== "") { this.setHelperText("streetHelperText", "") }
          this.setValue(name, value)
          break
        case "streetNumber": 
          if(value === "") { this.setHelperText("streetNumberHelperText", "házszám megadása kötelező") }
          if(value !== "") { this.setHelperText("streetNumberHelperText", "") }
          this.setValue(name, value)
          break
        default: this.setValue(name, value)
          break
      }
    }
    else {
      await this.setState({ [e.name]: e.value });
      this.props.onChange(({target: {name: this.props.name, value: JSON.stringify(this.state)}}))
    }
    
  };
  sortPropsToState = () => {
    if(this.props.value === undefined || this.props.value === "" || this.props.value === "null") return null
    for (let [key, value ] of Object.entries(JSON.parse(this.props.value))) {
      this.setState({ [key]: value });
    }
  }

  componentDidMount = () => {
    this.sortPropsToState()
  }

  render() {
    return (
      <React.Fragment>
        <TextField
            id="zip"
            className="zip"
            name="zip"
            label={this.props.dictionary['app_order_zip'].translation[this.props.selectedLanguage]}
            value={this.state.zip}
            error={this.state.zipHelperText !== ""}
            helperText={this.state.zipHelperText}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            type="number"
            variant="filled"
        />
        <TextField
            id="city"
            className="city"
            name="city"
            label={this.props.dictionary['app_order_city'].translation[this.props.selectedLanguage]}
            value={this.state.city}
            error={this.state.cityHelperText !== ""}
            helperText={this.state.cityHelperText}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
        />             
        <TextField
            id="state"
            className="state"
            name="state"
            label={"Megye"}
            value={this.state.state}
            error={this.state.stateHelperText !== ""}
            helperText={this.state.stateHelperText}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
        />           
        <TextField
            id="street"
            className="street"
            name="street"
            label={"Utca"}
            value={this.state.street}
            error={this.state.streetHelperText !== ""}
            helperText={this.state.streetHelperText}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
        />
        <TextField
            id="streetNumber"
            className="streetNumber"
            name="streetNumber"
            label={"Házszám"}
            value={this.state.streetNumber}
            error={this.state.streetNumberHelperText !== ""}
            helperText={this.state.streetNumberHelperText}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
        />
        <TextField
            id="floor"
            className="floor"
            name="floor"
            label={"Emelet, ajtó"}
            value={this.state.floor}
            error={false}
            helperText={""}
            onChange={this.onChangeValue}
            onBlur={this.props.onBlur}
            margin="normal"
            variant="filled"
        />                
      </React.Fragment>
    );
  }
}

export default Address;
