import React, { useState } from "react";
import { Popover } from "@material-ui/core";
import LanguageIcon from '@material-ui/icons/Language';

function Language(props) {
  
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const setLangEN = () => {
      props.setLang("en")
  }
  const setLangHU = () => {
    props.setLang("hu")
  }
  return (
      <div className="languageIconContainer">
        <LanguageIcon onClick={handleClick} />
        <Popover
          id={"language-popover"}
          open={anchorEl !== null}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center"
          }}
        >
          <div className="languagePopover">
            <img src={props.endpoint+"/assets/img/base/hu.png"} alt="hu-flag" onClick={setLangHU} />
            <img src={props.endpoint+"/assets/img/base/en.png"} alt="en-flag" onClick={setLangEN}/>
          </div>
        </Popover>
    </div>
  );
}

export default Language;
