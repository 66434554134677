import React, { Component } from "react"
import { withRouter } from "react-router-dom"
import LoginType from "../../../components/Login/LoginType"
import GuestEmail from "../../../components/Login/GuestEmail"

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showRegisterForm: false,
      showGuestEmailForm: false,
      loginReady: this.props.userLoggedIn,
    };
  }
  toggleLoginReady = () => {
    this.setState({
      loginReady: !this.state.loginReady,
    });
    if(typeof this.props.setLoginFormReady === "function") this.props.setLoginFormReady(!this.state.loginReady);
  };
  setLoginReady = (e) => {
    this.setState({
      loginReady: e,
    });
    if(typeof this.props.setLoginFormReady === "function") this.props.setLoginFormReady(e);
  }
  toggleRegisterForm = () => {
    this.setState({
      showRegisterForm: !this.state.showRegisterForm,
      showGuestEmailForm: false,
    });
  };
  toggleGuestForm = () => {
    this.setState({
      showGuestEmailForm: !this.state.showGuestEmailForm,
      showRegisterForm: false,
    });
  };
  componentDidMount() {
    this.setLoginReady(this.props.userLoggedIn)
  }
  componentDidUpdate = (oldProps) => {
    if (oldProps.userLoggedIn !== this.props.userLoggedIn) {
      this.setState({
        loginReady: this.props.userLoggedIn,
      });
      this.setLoginReady(this.props.userLoggedIn)
    }
  };
  render() {
    if(!this.props.userLoggedIn) {
      return (
        <div className="loginFormContainer hidden appear">
          <div className="loginMessage">
            <h2>A folytatáshoz jelentkezz be</h2>
          </div>
          <div className="registerEmail">
            <div className="registerMessage" onClick={this.toggleRegisterForm}>
              <h3>Regisztrálok / belépek</h3>
            </div>
            {this.state.showRegisterForm && <LoginType
              validateUserPin={this.props.validateUserPin}
              generateUserPin={this.props.generateUserPin}
              userLoginWithCredentials={this.props.userLoginWithCredentials}
            />}
          </div>
          {this.props.shopAsGuest && <div className="shopAsGuest">
            <div className="guestMessage" onClick={this.toggleGuestForm}>
              <h3>Regisztráció nélkül vásárolok</h3>
            </div>
            {this.state.showGuestEmailForm && (
              <GuestEmail toggleLoginReady={this.toggleLoginReady} />
            )}
          </div>}
        </div>
      )
    } 
    else return null
  }
}
export default withRouter(Login);
