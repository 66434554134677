import React, { Component } from 'react'
import MuiExpansionPanel from '@material-ui/core/ExpansionPanel'
import MuiExpansionPanelSummary  from '@material-ui/core/ExpansionPanelSummary'
import MuiExpansionPanelDetails  from '@material-ui/core/ExpansionPanelDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TextField from '@material-ui/core/TextField'
import Address from '../../../components/Profile/Address'

class InvoiceDetails extends Component{
  constructor(props) {
    super(props);
    this.state = {
      expanded: this.props.expanded
    }
  }
  setExpanded = (e) => {
    this.setState({
      expanded: e
    })
  }
  handlePanelChange = panel => (event, isExpanded) => {
    this.setExpanded(isExpanded)
  }
  componentDidUpdate(oldProps) {
    if(oldProps.expanded !== this.props.expanded) {
      this.setExpanded(this.props.expanded)
    }
  }
  render() {
    return (
      <MuiExpansionPanel 
        square={true}
        className={"hidden appear"} 
        expanded={this.state.expanded}
        onChange={this.handlePanelChange('panel1')}
      >
        <MuiExpansionPanelSummary
          expandIcon={<ExpandMoreIcon color={"inherit"}/>}
          aria-controls={"panel-a-content"}
          id={"panel-a-header"}
        >       
          {this.props.dictionary['app_order_invoiceDetails'].translation[this.props.selectedLanguage]}
        </MuiExpansionPanelSummary>
        <MuiExpansionPanelDetails>
            <TextField
              id="invoiceName"
              className="name"
              name="invoiceName"
              label={this.props.dictionary['app_order_nameOrCompany'].translation[this.props.selectedLanguage]}
              value={this.props.values.invoiceName}
              error={this.props.errors.invoiceName && this.props.touched.invoiceName}
              helperText={this.props.errors.invoiceName}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              margin="normal"
              variant="filled"
            />
            <Address
              id={"invoiceAddress"}
              name={"invoiceAddress"}
              value={this.props.values.invoiceAddress}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              dictionary={this.props.dictionary}
              selectedLanguage={this.props.selectedLanguage}
              endpoint={this.props.endpoint}      
            />
            <TextField
              id="invoiceTax"
              className="tax"
              name="invoiceTax"
              label={this.props.dictionary['app_order_vat'].translation[this.props.selectedLanguage]}
              value={this.props.values.invoiceTax}
              error={this.props.errors.invoiceTax && this.props.touched.invoiceTax}
              helperText={this.props.errors.invoiceTax}
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              margin="normal"
              variant="filled"
            />
        </MuiExpansionPanelDetails>
      </MuiExpansionPanel>
    )
  }
}
export default InvoiceDetails