import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel'
import 'bootstrap/dist/css/bootstrap.min.css'

class FullPageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedIndex: 0 }
  }

  handleSelect = (selectedIndex, e) => {
    this.setState({ selectedIndex })
  }

  render() {
    return (
      <div className={"fullPageCarousel"}>
        <Carousel activeIndex={this.state.selectedIndex} onSelect={this.handleSelect}>
          {this.props.items.map((item, index) => <Carousel.Item key={index}>
            <div className="itemBox">
              <div className="itemBoxHeader">{item.title}</div>
              <div className="body">{item.text}</div>
            </div>
          </Carousel.Item>)}
        </Carousel>
      </div>
    );
  }
}

export default FullPageCarousel;