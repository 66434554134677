/*eslint-disable eqeqeq*/ 
import React, { Component } from "react";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";
//import DeliveryAddress from './DeliveryAddress'
import InvoiceDetails from "./InvoiceDetails";
import DeliveryDetails from "./DeliveryDetails";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
//import {NavLink} from 'react-router-dom'
import Button from "@material-ui/core/Button";
import { Formik } from "formik";
import * as Yup from "yup";
import { withRouter } from "react-router-dom";

const basicSchema = Yup.object().shape({
  invoiceName: Yup.string()
    .max(50, "Túl hosszú!")
    .required("név megadása kötelező"),
});
const deliverySchema = Yup.object().shape({
  invoiceName: Yup.string()
    .max(50, "Túl hosszú!")
    .required("név megadása kötelező"),
  name: Yup.string().max(50, "Túl hosszú!"),
  telNumber: Yup.string()
    .min(2, "Túl rövid!")
    .max(50, "Túl hosszú!")
    .matches(/^[0-9]*$/, {
      message: "helytelen formátum, csak számot tartalmazhat.",
    }),
  notes: Yup.string().min(2, "Túl rövid!").max(50, "Túl hosszú!"),
});
const deliverySameDataSchema = Yup.object().shape({
  invoiceName: Yup.string()
    .max(50, "Túl hosszú!")
    .required("Név megadása kötelező"),
  name: Yup.string().max(50, "Túl hosszú!"),
  telNumber: Yup.string()
    .min(2, "Túl rövid!")
    .max(50, "Túl hosszú!")
    .matches(/^[0-9]*$/, {
      message: "helytelen formátum, csak számot tartalmazhat.",
    }),
  notes: Yup.string().min(2, "Túl rövid!").max(50, "Túl hosszú!"),
});

const WhiteCheckbox = withStyles({
  root: {
    color: "#000",
    "&$checked": {
      color: "#4CAF50",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginReady: false,
      invoiceNeeded: true,
      invoiceDeliverySameData: true,
    };
  }
  setDeliveryType = (e) => {
    this.props.setDeliveryType(e);
  };
  setPaymentType = (e) => {
    this.props.setPaymentType(e);
  };
  handleDeliverySetting = (event, option) => {
    if (option !== null) {
      switch (option) {
        case "store":
          this.setPaymentType("payAtStore");
          this.setDeliveryType(option);
          break;
        case "delivery":
          this.setPaymentType("simplePay");
          this.setDeliveryType(option);
          break;
        default:
          this.setDeliveryType(option);
      }
    }
  };
  handlePaymentSetting = (event, option) => {
    if (option !== null) {
      this.setPaymentType(option);
    }
  };
  isPaymentEnabled(paymentType) {
    if(this.props.deliveryConfig) {
      return this.props.deliveryConfig[this.props.deliveryType].paymentTypes.includes(paymentType)
    } else return this.props.paymentConfig.includes(paymentType)
  }
  setInvoiceNeeded = (e) => {
    this.setState({
      invoiceNeeded: e,
    });
    localStorage["invoiceNeeded"] = e;
  };
  toggleInvoiceNeeded = () => {
    this.setState({
      invoiceNeeded: !this.state.invoiceNeeded,
    });
    localStorage["invoiceNeeded"] = !this.state.invoiceNeeded;
  };
  toggleInvoiceDeliverySameData = () => {
    this.setState({
      invoiceDeliverySameData: !this.state.invoiceDeliverySameData,
    });
    localStorage["invoiceDeliverySameData"] = !this.state
      .invoiceDeliverySameData;
  };
  setInvoiceDeliverySameData = (e) => {
    this.setState({
      invoiceDeliverySameData: e,
    });
    localStorage["invoiceDeliverySameData"] = e;
  };
  onSubmitForm = (formValues) => {
    console.log(formValues)
    let invoiceInfo = {
      name: formValues.invoiceName !== undefined ? formValues.invoiceName : "",
      address: formValues.invoiceAddress !== undefined ? formValues.invoiceAddress : "",
      tax: formValues.invoiceTax !== undefined ? formValues.invoiceTax : "",
    };
    //this.props.setInvoiceInfo(invoiceInfo);
    let deliveryInfo = {
      name: formValues.name !== undefined ? formValues.name : "",
      telNumber: formValues.telNumber !== undefined ? formValues.telNumber : "",
      address: formValues.deliveryAddress !== undefined ? formValues.deliveryAddress : "",
      notes: formValues.notes !== undefined ? formValues.notes : "",
    };
    if (this.state.invoiceDeliverySameData) {
      deliveryInfo.address = invoiceInfo.address;
      deliveryInfo.name = invoiceInfo.name;
    }
    //this.props.setDeliveryInfo(deliveryInfo);
    this.props.setUserInfo(invoiceInfo, deliveryInfo)
  };
  renderErrors = (e) => {
    let errorString = "";
    Object.keys(e).forEach(function (item) {
      errorString += e[item] + ", ";
    });
    errorString = errorString.substring(0, errorString.length - 2);
    if (errorString !== "") {
      return (
        <div className="formErrorContainer hidden appear">{errorString}</div>
      );
    } else return null;
  };
  componentDidMount() {
    /*if(localStorage['invoiceNeeded'] === "true") {
          this.setState({
            invoiceNeeded: true
          })
        }*/
    if (localStorage["invoiceDeliverySameData"] === "false") {
      this.setState({
        invoiceDeliverySameData: false,
      });
    }
  }
  render() {
    let delivery = localStorage["deliveryType"] === "delivery";
    let userData = this.props.userData;
    let deliveryInfo = {}
    let invoiceInfo = {}
    if(userData != null) {
      deliveryInfo = {
        name: userData.last_name + " " + userData.first_name,
        telNumber: userData.tel_number,
      };
      invoiceInfo = {};
    }
    let validationSchema = basicSchema;

    //if(localStorage['userData'] !== undefined) userData = JSON.parse(localStorage['userData'])
    if (delivery) validationSchema = deliverySchema;
    if (this.state.invoiceDeliverySameData)
      validationSchema = deliverySameDataSchema;
    if (localStorage["deliveryInfo"] != undefined) deliveryInfo = JSON.parse(localStorage["deliveryInfo"]);
    if (localStorage["invoiceInfo"] != undefined) invoiceInfo = JSON.parse(localStorage["invoiceInfo"]);
    if (this.props.dictionary != null) {
      return (
        <div className="detailsContainer hidden quickAppear">
          <Formik
            initialValues={{
              name: deliveryInfo.name != undefined ? deliveryInfo.name : "",
              telNumber: userData != undefined && userData.tel_number != undefined ? userData.tel_number : deliveryInfo.telNumber != undefined ? deliveryInfo.telNumber : "",
              deliveryAddress: deliveryInfo.address != undefined ? deliveryInfo.address : "",
              notes: deliveryInfo.notes != undefined ? deliveryInfo.notes : "",
              invoiceName: userData != undefined && userData.name != undefined ? userData.name : invoiceInfo.name != undefined ? invoiceInfo.name : "",
              invoiceAddress: userData != undefined && userData.address !== undefined ? JSON.stringify(userData.address) : invoiceInfo.address != undefined ? invoiceInfo.address : "",
              invoiceTax: invoiceInfo.tax != undefined ? invoiceInfo.tax : "",
            }}
            onSubmit={(values, { setSubmitting }) => {
              setTimeout(() => {
                this.onSubmitForm(values);
                setSubmitting(false);
              }, 400);
            }}
            validationSchema={validationSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              /* and other goodies */
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="deliveryContainer hidden appear">
                  <div className="title"></div>
                  <div className="invoiceContainer">
                    <div className="invoiceDetails">
                      <InvoiceDetails
                        expanded={this.state.invoiceNeeded}
                        setInvoiceNeeded={this.setInvoiceNeeded}
                        dictionary={this.props.dictionary}
                        selectedLanguage={this.props.selectedLanguage}
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        endpoint={this.props.endpoint}
                      />
                    </div>
                  </div>
                  {this.props.deliveryConfig && <div className="deliveryOptions">
                    <ToggleButtonGroup
                      value={this.props.deliveryType}
                      exclusive
                      onChange={this.handleDeliverySetting}
                      aria-label="text deliveryOption"
                    >
                      <ToggleButton 
                        value="delivery" 
                        aria-label="centered"
                        disabled={this.props.deliveryConfig.delivery === undefined}
                      >
                        Kiszállítás futárral
                      </ToggleButton>
                      <ToggleButton 
                        value="store" 
                        aria-label="centered"
                      >
                        Személyes átvétel
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>}
                  {this.props.deliveryConfig && this.props.deliveryType === "delivery" && (
                    <div className="deliveryData hidden appear">
                      <div className="title"></div>
                      <div
                        className="invoiceDeliverySameDataText hidden quickAppear"
                        onClick={this.toggleInvoiceDeliverySameData}
                      >
                        <div className="checkbox">
                          <WhiteCheckbox
                            checked={this.state.invoiceDeliverySameData}
                            onChange={this.toggleInvoiceDeliverySameData}
                            value="sameData"
                            color="default"
                            inputProps={{
                              "aria-label": "secondary checkbox",
                            }}
                          />
                        </div>
                        <div className="text">
                          A szállítási adatok megegyeznek a számlázási
                          adatokkal.
                        </div>
                      </div>
                      <div className="deliveryAddressContainer">
                        <DeliveryDetails
                          expanded={!this.state.invoiceDeliverySameData}
                          setInvoiceDeliverySameData={
                            this.setInvoiceDeliverySameData
                          }
                          dictionary={this.props.dictionary}
                          selectedLanguage={this.props.selectedLanguage}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          endpoint={this.props.endpoint}
                        />
                      </div>
                    </div>
                  )}
                </div>
                {this.props.paymentConfig && <div className="paymentContainer hidden appear">
                  <div className="title">Fizetési mód</div>
                  <div className="paymentOptions">
                    <ToggleButtonGroup
                      value={this.props.paymentType}
                      exclusive
                      onChange={this.handlePaymentSetting}
                      aria-label="text paymentOptions"
                    >
                      {this.props.paymentConfig.includes("simplePay") && <ToggleButton
                        value="simplePay"
                        aria-label="centered"
                        disabled={!(this.isPaymentEnabled("simplePay"))}
                      >
                        Előre fizetés bankkártyával
                      </ToggleButton>}
                      {this.props.paymentConfig.includes("payAtStore") && <ToggleButton
                        value="payAtStore"
                        aria-label="centered"
                        disabled={!(this.isPaymentEnabled("payAtStore"))}
                      >
                        Fizetés üzletben
                      </ToggleButton>}
                    </ToggleButtonGroup>
                  </div>
                </div>}
                {this.renderErrors(errors)}
                <div className="finalizeContainer">
                  <Button
                    className="submitOrder"
                    variant="contained"
                    color="primary"
                    disabled={isSubmitting}
                    type="submit"
                  >
                    Rendelés összegzése
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      );
    } else return null;
  }
}
export default withRouter(Details);
