import React, {Component} from 'react'
import MinisiteContext from '../MinisiteContext'
import PageType0 from '../PageTypes/PageType0'
import PageType1 from '../PageTypes/PageType1'
import PageType2 from '../PageTypes/PageType2'
import PageType3 from '../PageTypes/PageType3'
import PageType4 from '../PageTypes/PageType4'
import LoadAssets from '../../../components/LoadAssets'

class Page extends Component  {
  renderPage = () => {
    switch(this.props.pageType) {
      case 0: return(<PageType0 htmlCode={this.props.content.htmlCode} />)
      case 1: return(<PageType1 content={this.props.content} />)
      case 2: return(<PageType2 content={this.props.content} />)
      case 3: return(<PageType3 content={this.props.content} assets={this.context.assets} />)
      case 4: return(<PageType4 content={this.props.content} />)
      default: console.log("Default page.")
    }
  }
  render() {
    let backgroundImage = "url('"+LoadAssets(this.props.backgroundImage, this.context.assets)+"')"
    return (
      <div style={{height: "100%", width: "100%", backgroundImage: backgroundImage, backgroundSize: "cover", backgroundRepeat: "no-repeat"}}>
        {this.renderPage()}
      </div>
    );
  }
}
Page.contextType = MinisiteContext;
export default Page;
