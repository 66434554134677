import React, { Component } from 'react'
import { Popover } from "@material-ui/core"
import Badge from '@material-ui/core/Badge'
import Cart from '@material-ui/icons/ShoppingCart'
import Basket from '../../components/Order/Basket'

class CartIcon extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            anchorEl: null,
            selectedItemCount: this.props.selectedItemCount
        }
    }

    handleClickOnEmptyCart = () => {
        console.log("emptyCartClicked.")
    }
    handleClickOnCart = () => {
        console.log("cartClicked.")
    }
    handleClick = event => {
        this.setState({
            anchorEl: event.currentTarget
        })
    }
    handleClose = () => {
        this.setState({
            anchorEl: null
        });
    }
    componentDidUpdate(oldProps) {
        if(oldProps.selectedItemCount !== this.props.selectedItemCount){
            this.setState({
                selectedItemCount: this.props.selectedItemCount
            })
        }
    }
    render() {
        return(
            <div className="cartIconContainer active">
                <div onClick={this.handleClick}>
                    <Badge color="secondary" badgeContent={this.state.selectedItemCount}>             
                        <Cart />
                    </Badge>
                </div>
                <Popover
                    id={"cart-popover"}
                    open={this.state.anchorEl !== null}
                    anchorEl={this.state.anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                    >
                    <div className="cartPopover">
                    {this.props.selectedItemCount>0 &&
                        <Basket 
                            orderItemTree={this.props.orderItemTree} 
                            isItemVisible={this.props.isItemVisible}
                            selectedLanguage={this.props.selectedLanguage}
                            dictionary={this.props.dictionary}
                            assets={this.props.assets}
                            endpoint={this.props.endpoint}
                            currencies={this.props.currencies}
                            addClass="minimized"
                            resetOrderItemtree={this.props.resetOrderItemtree}
                            setOrderItemCount={this.props.setOrderItemCount}
                            closePopover={this.handleClose}
                            setItemsToSubmit={this.props.setItemsToSubmit}
                            getDeliveryCost={this.props.getDeliveryCost}
                            itemView={this.props.itemView}
                        />}
                    {this.props.selectedItemCount===0 && <div className="emptyBasketMessage">A kosarad üres.</div>}
                    </div>
                </Popover>
            </div>
        )
    }
}
export default CartIcon;
  

