import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import { toast } from 'react-toastify'

class GuestEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: localStorage['userEmail'] !== undefined ? localStorage['userEmail'] : "",
        };
    }
    notify = () => toast.warn("Hibás email cím formátum!")
    modifyEmail = () => {
        localStorage.removeItem('userEmail')
        this.setState({
            userEmail: "",
        })
    }
    saveEmail = () => {
        localStorage['userEmail'] = this.state.userEmail
        this.props.toggleLoginReady()
    }
    handleEmailChange = (event) => {
        this.setState({userEmail: event.target.value});
    }
    handleEmailFormSubmit = async (event) => {
        event.preventDefault();
        if(this.state.userEmail.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            this.saveEmail()
        } else this.notify()     
    }
    render() {
        return(
            <div className="guestEmailFormContainer">
                <form className="emailForm hidden quickAppear" onSubmit={this.handleEmailFormSubmit}>
                    {!this.state.emailSent && <p className="hidden appear">Adj meg egy email címet, amire elküldjük a rendelésed visszaigazolását!</p>}
                    <TextField
                        id="email"
                        name="email"
                        label={"E-mail cím"}
                        value={this.state.userEmail}
                        onChange={this.handleEmailChange}
                        margin="normal"
                        type="email"
                        required
                    />
                    {<Button type="submit" color="primary" variant="contained">rögzít</Button>}
                </form>
            </div>
        )
    }
}
export default GuestEmail;



